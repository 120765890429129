<template>
	<div>
		<v-chart id="id" style="margin-top:20px;width:100%;" :class="{ 'chart': true }" :option="option" />
		<el-button @click="hidelable">{{ labelstatustext }}</el-button>
	</div>
	
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GridComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { PieChart, xAxis, LineChart } from "echarts/charts";
import {
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	ToolboxComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
	BarChart,
	GridComponent,
	CanvasRenderer,
	PieChart,
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	ToolboxComponent,
	LineChart,
]);

export default {
	name: "bar",
	components: {
		VChart,
	},
	provide: {
		[THEME_KEY]: "light",
	},

	props: {
		data: {
			type: Array,
			default: function () {
				return [];
			},
		},
		width100: {
			type: Boolean,
			default: false
		},
		showField: {
			type: Array,
			default: function () {
				return [];
			},
		},
	},
	mounted() {
	
			this.createOption();
	},

	watch: {
		data(nv, ov) {
			this.option = {};
			this.$nextTick(() => {

				this.createOption();
			})
		},
		showField(nv) {
			this.defaultField = nv;
		},
	},

	data() {
		return {
			defaultField: [],
			option: {},
			labelstatus:true,
			labelstatustext:'隐藏数值',
		};
	},

	methods: {
		hidelable(){
				
			this.option.series.map(item=>{
				item.label.show = !this.labelstatus
				return item;
			})
			if(this.labelstatus === true){
				this.labelstatustext  = '显示数值'
				this.labelstatus = false
			}else{
				this.labelstatustext  = '隐藏数值'
				this.labelstatus = true
			}

		},
		
		createOption() {

			const xAxisData = [];

			this.data.map((item) => {
				if (item.ym) {
					xAxisData.push(item.ym);
				}
				if (item.usecate_name) {
					xAxisData.push(item.usecate_name);
				}
				if (item.area) {
					xAxisData.push(item.area);
				}
			});


			var labelOption = {
				show: true,
				rotate: 0,
				// align: 'center',
				// verticalAlign: 'middle',
				// position: 'Top',
				// formatter: '{c}  {name|{a}}',
				formatter: '{c}',
				fontSize: 10,
				rich: {
					name: {
						textBorderColor: '#fff'
					}
				}
			};

			let mianji = [], jianmian = [], loumian = [], jine = [], yijialv = [], zongshu = [];

			this.data.map(item => {
				mianji.push(item.total_acreage)
				jianmian.push(item.total_buildacreage)
				loumian.push(item.floorprice)
				jine.push(item.total_tradeprice)
				yijialv.push(item.overflow_table)
				zongshu.push(item.number)
			})


			const option = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				legend: {
					data: ['总面积(㎡)', '总建筑面积(㎡)', '总金额(万元)', '楼面价(元/㎡)', '溢价率(%)', '宗数']
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					left: 'right',
					top: 'center',
					feature: {
						mark: { show: true },
						// dataView: { show: true, readOnly: false },
						magicType: { show: true, type: ['line', 'bar', 'stack', 'tiled'] },
						// restore: { show: true },
						saveAsImage: { show: true }
					}
				},
				xAxis: [
					{
						type: 'category',
						axisTick: { show: false },
						data: xAxisData
					}
				],
				yAxis: [
					{
						type: 'value'
					}
				],
				series: [
					{
						name: '总面积(㎡)',
						type: 'bar',
						barGap: 0,
						label: labelption,
						data: mianji,
						barMaxWidth:'8%',
						barMinWidth:'6%',
						barGap:'10%'
					},
					{
						name: '总建筑面积(㎡)',
						type: 'bar',
						label: labelOption,
						data: jianmian,
						barMaxWidth:'8%',
						barMinWidth:'6%'
					},
					{
						name: '总金额(万元)',
						type: 'bar',
						label: labelOption,
						data: jine,
						barMaxWidth:'8%',
						barMinWidth:'6%'
					},
					{
						name: '楼面价(元/㎡)',
						type: 'bar',
						label: labelOption,
						data: loumian,
						barMaxWidth:'8%',
						barMinWidth:'6%'
					},
					{
						name: '溢价率(%)',
						type: 'bar',
						label: labelOption,
						data: yijialv,
						barMaxWidth:'8%',
						barMinWidth:'6%'
					},
					{
						name: '宗数',
						type: 'bar',
						label: labelOption,
						data: zongshu,
						barMaxWidth:'8%',
						barMinWidth:'6%'
					}

				]
			};

			this.option = option;
		}
	},

	// labelOption() {
	// 	return {
	// 		show: true,
	// 		rotate: 75,
	// 		align: "left",
	// 		verticalAlign: "middle",
	// 		position: "insideBottomLeft",
	// 		distance: 8,
	// 		formatter: "{c}  {name|{a}}",
	// 		fontSize: 12,
	// 		rich: {
	// 			name: {},
	// 		},
	// 	};
	// }

};
</script>

<style scoped>
.chart {
	height: 400px;
	width: 700px;
	/* width:100% */
}

.width100 {
	height: 400px;
	width: 100%;
}
</style>