import request from '@/utils/request'
import requestMock from '@/utils/requestMock'
//mock数据使用  requestMock需要换成request 删除此行





//获取用户是否展示bigdata成交数据权限
export function userShow(params){
  return request({
    url: '/frontend/bigdata/landdata/is-bigdata-landdata-vip-show',
    method: 'post',
    params
  })
}






//首页list
export function indexlist(params){
  return request({
    // url: '/mock/index.json',
    url: '/frontend/bigdata/economic/home-page',
    method: 'post',
    params
  })
}



//首页list
export function dataindexlist(params){
  return request({
    url: '/frontend/bigdata/landdata/data-center',
    method: 'post',
    params
  })
}





export function getdialogList(params){
  return request({
    url: '/frontend/bigdata/landdata/calendar-list',
    method: 'post',
    params
  })
}





//日历页面  获取选中日期列表  传入年月日 2022-06-19
export function getDateList(params){
  return requestMock({
    url: '/mock/datelist.json',
    method: 'get',
    params
  })
}



//日历页面  获取日期列表  传入年月   2022-07
export function getMarkList(params){
  return requestMock({
    url: '/mock/dateMarkList.json',
    method: 'get',
    params
  })
}






// export function indexlist(params) {
//   return request({
//     url: '',
//     method: 'post',
//     params
//   })
// }