<template>
	<div class="con">
		<el-button :size="size"  @click="download" type="primary">{{ text }}</el-button>

		<el-dialog title="" :modal="false" append-to-body :visible.sync="dialogPay" width="40%" @close="colsedialog">
			<div class="innerbox">
				<div class="downtitle" >您正在使用土拍网支付功能</div>
				<div class="downjine" >支付金额: <span style="color: rgba(255, 0, 0, 0.805);
			font-weight: 600;" class="jine">{{ price }}元</span></div>
					<div v-if="!enough" class="enongh" >
						<div  class="balance"  >账户余额不足，余额：<span class="jine">{{ balance }}元</span></div>
				
						<div class="paypic" style="display: flex;
	justify-content: center;
	align-items: center;
">
		<img src="@/assets/notagree.png" v-if="!agree" class="pic" style="	width: 186px;
		height: 186px;
		background: #aaa;" />

					<img :src="qrcode" v-if="agree" class="pic" style="	width: 186px;
		height: 186px;
		background: #aaa;" />
				
				</div>

						<div  class="paymark" >
							<img class="imgpay" src="@/assets/icon/weixin.png" alt="">
							<span class="paytext">微信扫码支付</span>
						</div>
					</div>
					<div  v-if="enough" class="less">

						<div  class="balance"  >账户余额：<span class="jine">{{ balance }}元，将优先从您的账户扣除</span></div>
						<div class="btndown" @click="confirm">确定下载</div>
					</div>

		

				<div class="chongzhi">
					<div class="topbtn"  @click="topay">
						<img class="btnicon" src="@/assets/icon/qian.png" alt="">
						<div class="adcon">土拍数据包，充值更划算</div>
						<img class="btnicon"  src="@/assets/icon/hou.png" alt="">
					</div>
					<div class="list">
						<div class="listbtn"  @click="topay"  v-for="item in recharge" >充{{ item.recharge_amount }}得{{ item.actual_amount }}
							<span v-if="item.limit > 0 " >(首次)</span>
						</div>
					</div>
				</div>



		
			

				<div class="tongyi"  v-if="!enough" >
					<span style="">
					<input v-model="agree"  class="agree" type="checkbox"/>
					阅读并同意
					<a  class="xieyi" style="	color: #1288c8;
			cursor: pointer;" @click="toxieyi" >《土拍网内容授权许可协议》</a></span>
				</div>


			</div>
		</el-dialog>
	</div>
</template>
<script>


import { placeOrder, paystatus, downloadfile, downloadExcel,confirmapi } from '@/api/orderpay.js'
export default {
	name: 'WorkspaceJsonPayDialog',
	props: {
		text: String,
		params: {
			type: Object
		},
		icon: {
			type: String,
			default: 'el-icon-download'
		},
		size: {
			type: String,
			default: 'mini'
		},
		type: {
			type: String,
			default: 'image',//image excel //图片和excel
		},
		goDownload: {
			type: Boolean,
			default: false,//image excel //图片和excel
		}

	},
	data() {
		return {

			agree:false,
			dialogPay: false,
			dialogDown: false,
			qrcode: '',
			price: 0,
			timer: {},
			order_no: '',//订单号
			t: {},
			enough:null,
			balance:null,
			recharge:[]

		};
	},


	methods: {
		toxieyi(){
			window.open('https://tupaibase.chinatupai.com/FtM8zcIvpXEiJ9d8t5G-o8JmMSJo')
		},
		colsedialog() {
			window.clearInterval(this.t)
		},

		topay(){
			const usercenter = process.env.VUE_APP_LOGIN_BASIC
			this.dialogPay = false
			window.open(usercenter + 'member/buyvip')
		},

		//服务器下载
		download() {
			const params = this.params
			
			if (params.condition == null || params.condition.file_ids === "") {
				this.$message.error('请选择要下载的文件')
			} else {
				placeOrder(params).then(res => {
					const order = res.data.order
					this.price = res.data.order.price
					this.order_no = res.data.order.order_no
					this.enough = res.data.enough
					this.balance = res.data.balance
					this.recharge = res.data.recharge
					if (order.price === 0) {
						//下载

						this.downConfirm()

					} else {
						//支付//处理地址//开启轮询
						if(!this.enough){
							this.getPaystatus(res.data.order.order_no)
						}
					

						this.qrcode = res.data.qrcode

						this.dialogPay = true
					
					}

				})


			}
			// console.log('params', params)
			// let _this = this;


		},
		downConfirm() {
			this.dialogPay = false
			this.$emit('colsedialog' ,true)
			this.$confirm('订单已经生成，点击确定按钮进行下载', '下载提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.getDownFile()
			})
		},


		 confirm(){
			confirmapi({order_no:this.order_no}).then(res=>{
				this.downConfirm()
			})
		},


		getDownFile() {
			if (this.type === 'excel') {
				downloadExcel({ order_no: this.order_no }).then(res => {
					window.location.href = res.data.fileUrl
				})
			} else {
				downloadfile({ order_no: this.order_no }).then(res => {
					window.location.href = res.data;
				})
			}
		},
		//获取支付状态 轮询
		getPaystatus(order_no) {
			var that = this
			var once = 1

			this.t = window.setInterval(function () {
				once = once + 1

				paystatus({ order_no: order_no }).then(res => {

					if (res.data.pay_status === 1) {
						//支付成功 结束轮循
						window.clearInterval(that.t)
						// //关闭支付框
						that.dialogPay = false

						that.downConfirm()

					}

				})
				// console.log('once', once)
				if (once > 60) {
					window.clearInterval(that.t)
					// //关闭支付框
					that.dialogPay = false
				}

			}, 2000);

		}




	},

	destoryed() {
		window.clearInterval(t)
	}

};
</script>

<style lang="scss" scoped>


.agree{
	position: relative;
	top:2px;
}


.btndown{
	width: 238px;
	height: 40px;
	background: #28A7E1;
	border-radius: 4px;
	font-size: 14px;
	font-family: PingFangSC, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 40px;
	text-align: center;
	margin:30px;
	cursor: pointer;
}
.enongh{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.less{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.con {
	z-index: 9;
	
}

.chongzhi{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.topbtn{
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 238px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #28A7E1;
		img{
			width: 16px;
			height: 16px;
		}
		.adcon{
			margin:0 10px;
			height: 22px;
			font-size: 14px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			color: #28A7E1;
			line-height: 22px;
		}
	}
	.list{
		display: flex;
		justify-content: center;
		.listbtn{
			cursor: pointer;
			width: 112px;
			height: 40px;
			background: rgba(229,245,252,0);
			border-radius: 4px;
			border: 1px solid #E4E4E4;
			margin:10px;
			text-align: center;
			font-size: 14px;
			font-family: PingFangSC, PingFang SC;
			font-weight: 500;
			color: #000000;
			line-height: 40px;

		}
	}
	

}
.innerbox{
	width:100%;
	display: flex;
	flex-direction: column;

}

.paytext{
	width: 96px;
	height: 22px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 22px;
}

 .downtitle {
	width: 100%;
	height: 25px;
	font-size: 18px;
	font-family: PingFangSC, PingFang SC;
	font-weight: 600;
	color: #000000;
	line-height: 25px;
	text-align: center;
}

.downjine {
	height: 20px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 22px;
	margin-top: 5px;
	display: flex;
	justify-content: center;
	align-items: center;



}

.balance{
	height: 20px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 22px;
	margin-top: 5px;
	display: flex;
	justify-content: center;
	align-items: center;

}

 .paypic {
	display: flex;
	justify-content: center;
	align-items: center;
	.pic {
		width: 146px;
		height: 146px;
		background: #aaa;
	}
}

.paymark {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5px;
	margin-bottom: 5px;

	.imgpay {
		height: 20px;
		width: 20px;
		margin-right: 10px;
	}

	span {}


}

.tongyi {
	display: flex;
		justify-content: center;
		align-items: center;

	span {
		height: 20px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #A0A0A0;
			line-height: 20px;
	}

	.xieyi {}
}
</style>