import request from '@/utils/request'



//成交分析区域 数据
export function  area(params) {
  return request({
    url: '/frontend/bigdata/analysis/area',
    method: 'post',
    params


  })
}



//成交分析时间数据


export function  time(params) {
  return request({
    url: '/frontend/bigdata/analysis/time',
    method: 'post',
    params
  })
}




//成交分析用途 数据
export function  usecate(params) {
  return request({
    url: '/frontend/bigdata/analysis/usecate',
    method: 'post',
    params
  })
}



//成交分析区域 数据
export function  areadown(params) {
  return request({
    url: '/frontend/bigdata/analysis/area-down',
    method: 'post',
    params

  })
}



//成交分析时间数据


export function  timedown(params) {
  return request({
    url: '/frontend/bigdata/analysis/time-down',
    method: 'post',
    params
  })
}




//成交分析用途 数据
export function  usecatedown(params) {
  return request({
    url: '/frontend/bigdata/analysis/usecate-down',
    method: 'post',
    params
  })
}