<template>
	<div class="groupbox">
		<div class="biaoti">统计时间:</div>
		<div class="danxuan">
			<el-radio-group v-model="where.type">
				<el-radio :label="1">月度</el-radio>
				<el-radio :label="2">季度</el-radio>
				<el-radio :label="3">年度</el-radio>
			</el-radio-group>
		</div>
		<div class="inputbox">
			<div class="yueselect">
				<el-date-picker v-if="where.type === 1" size="mini" style="width:200px" v-model="where.yuedu" type="monthrange"
					value-format="yyyy-MM" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份"
					@change="yueduchange">
				</el-date-picker>
			</div>
			<div class="jiduselect" v-if="where.type === 2" style="width:200px">
				<mo-quarter-picker :value.sync="where.jidu" @on-change="handleChange"></mo-quarter-picker>
			</div>
			<div v-if="where.type === 3" class="nianselect" style="width:200px">
				<yearPicker ref="statisticPicker" :initYear="niandu" @updateTimeRange="updateStatisticYear" />

			</div>
		</div>
	</div>
</template>

<script>
import yearPicker from '@/components/common/yearPicker'
export default {
	name: 'FrontendPcBigdataDategrounp',
	components: {
		yearPicker
	},
	watch: {
		where: {
			handler(nv, ov) {
				this.getEmitData()
			},
			immediate: true,
			deep: true
		},
		// yuedu: {
		// 	handler(nv, ov) {
		// 		this.getEmitData()
		// 	},
		// 	immediate: true,
		// 	deep: true
		// },
		// jidu: {
		// 	handler(nv, ov) {
		// 		this.getEmitData()
		// 	},
		// 	immediate: true,
		// 	deep: true
		// }
	},
	data() {
		return {
			where: {
				type: 1,//1 月 2季 3年
				yuedu: [],
				jidu: [],
			},

			niandu: [],
			start: '',
			end: '',
			pickerOptions: {
				disabledDate(time) {
					return (
						time.getFullYear() < "2009" ||
						time.getFullYear() > new Date().getFullYear()
					); // 注意是 || 不是 && 哦
				}
			}
		}
	},
	create() {

	},

	mounted() {

		let d = new Date
		let year1, month1, day1;
		[year1, month1, day1] = [d.getFullYear(), d.getMonth(), d.getDate()]
		const rangeMonth = [year1 + '-01', year1 + '-' + (parseInt(month1) + 1)] //当年一月 ，当年当月
		this.where.yuedu = rangeMonth

		//季度插件需要具体时间  
		const rangeSeason = [year1 + '-01' + '-01', year1 + '-' + (parseInt(month1) + 1) + '-' + day1]
		this.where.jidu = rangeSeason

		//年度插件是时间戳
		const date1 = new Date(year1 + '-01' + '-01')
		const date2 = new Date(year1 + '-' + (parseInt(month1) + 1) + '-' + day1)
		// console.log('data1', date1)
		const rangeYear = [Date.parse(date1), Date.parse(date2)]
		// console.log('rangeYear', rangeYear)
		this.niandu = rangeYear
	},

	methods: {
		getEmitData() {
			let params = {
				type: this.where.type,
			}
			switch (this.where.type) {
				case 1:
					//生成条件
					params.start = this.where.yuedu[0]
					params.end = this.where.yuedu[1]

					break;
				case 2:
					params.start = this.getSeasonStr(this.where.jidu[0])
					params.end = this.getSeasonStr(this.where.jidu[1])
				
					break;
				case 3:
					params.start = this.getYearStr(this.niandu[0])
					params.end = this.getYearStr(this.niandu[1])
				

					break;
			}
			if (params.start && params.end) {
				this.$emit('typedate', params)
			}

		},
		getYearStr(dateStream) {
			const date = new Date(dateStream);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
			const Y = date.getFullYear();
			return Y
		},
		getSeasonStr(str) {
			let season;
			const s = new Date(str)
			const [y1, m1] = [s.getFullYear(), s.getMonth()]
			const q1 = [0, 1, 2]
			const q2 = [3, 4, 5]
			const q3 = [6, 7, 8]
			const q4 = [9, 10, 11]
			if (q1.indexOf(m1) > -1) {
				season = 1
			}
			if (q2.indexOf(m1) > -1) {
				season = 2
			}
			if (q3.indexOf(m1) > -1) {
				season = 3
			}
			if (q4.indexOf(m1) > -1) {
				season = 4
			}

			return y1 + '-' + JSON.stringify(season)
		},


		updateStatisticYear(e) {
			console.log('====', e)
			// if(e.endYear === NaN){
			// 	e.endYear = e.startYear
			// }

			// if(e.startYear === NaN){
			// 	e.startYear = e.endYear
			// }

			const params = {
				type: 3,
				start: this.getYearStr(e.startYear),
				end: this.getYearStr(e.endYear - 1)
			}
			this.$emit('typedate', params)
		},
		yueduchange() {
			// console.log('yuedu', this.where.yuedu)
		},

		handleChange(e) {

		},
		prev() {
			this.year = this.year - 1
		},
		next() {
			this.year = this.year + 1
		}
	}

}

</script>

<style lang="scss" scoped>
.groupbox {
	display: flex;
	justify-content: flex-start;
	align-items: center;

}

.yueselect {
	display: flex;
	justify-content: flex-start;

}

::v-deep .el-range-editor--mini.el-input__inner {
	width: 200px;
}

.danxuan {
	margin-right: 20px;
}

.biaoti {
	height: 22px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 22px;
	margin-right: 20px;
}

::v-deep .el-radio {
	margin-right: 10px;
}




::v-deep picker__item__label {
	height: 36px;
	line-height: 36px;
	width: 60px;
}

::v-deep .quarter-picker__item__label {
	height: 36px;
	line-height: 36px;
	width: 60px;

}

::v-deep .quarter-picker__item--active .quarter-picker__item__label,
::v-deep .quarter-picker__item:hover .quarter-picker__item__label {
	background: #409EFF;

}
</style>