<template>
	<div>
		<div class="btngrounp">
			<el-radio-group size="mini" v-model="primary" @change="changePrimary">
				<el-radio-button size="mini" :label="1">按区域对比</el-radio-button>
				<el-radio-button size="mini" :label="2">按时间对比</el-radio-button>
				<el-radio-button size="mini" :label="3">按用途对比</el-radio-button>
			</el-radio-group>

		</div>
		<div class="actionarea">
			<div class="compare">
				<SortLayout class="sortarea" tag="div" :sort="sorts[index]">

					<div key="1" slot="a" class="quyu">
						<span class="labeltitle">所在区域:</span>
						<el-cascader style="width:270px;" :checkStrictly="true" :emitPath="false" v-model="condition.dcity_ids"
							size="mini" :options="citylist" :props="cascaderProp" collapse-tags clearable>
						</el-cascader>
					</div>

					<div key="2" slot="b" class="shijian">
						<dategroup @typedate="typedate"></dategroup>
					</div>

					<div key="3" slot="c" class="yongtu">
						<div class="inneryongtu">

							<div class="morechecked">
								<span class="labeltitle">用途:</span>
								<el-checkbox-group v-model="condition.usecate">
									<el-checkbox v-for="item in usecate" :key="item.id" :label="item.id">{{ item.name }}</el-checkbox>
								</el-checkbox-group>
							</div>

							<div class="kaiguan">
								<el-switch v-model="condition.afford" active-color="#409EFF" active-text="含纯保障" inactive-color="#ccc">
								</el-switch>
							</div>

						</div>

					</div>
				</SortLayout>

			</div>

			<div class="chaxunbtn">
				<el-button @click="search" icon="el-icon-search" type="primary">查询</el-button>
			</div>

		</div>
		<div class="changeRes" v-if="!isvip">
		<el-alert
				title="尊敬用户，您还不是超级vip,开通后可查看表格和图表。"
				type="success">
			</el-alert>
			<el-button size="mini" type="success" @click="toUcenter" style="margin-left:20px;">去升级VIP</el-button>
		</div>
		<div class="changeRes">
			
			<el-radio-group size="mini" v-model="mode" @change="changeMode">
				<el-radio-button size="mini" :label="1">表格</el-radio-button>
				<el-radio-button size="mini" :label="2">图表</el-radio-button>
			</el-radio-group>
			<el-button size="mini" type="primary" @click="down" style="margin-left:20px;">导出excel</el-button>
		</div>
		<div v-show="mode === 1" class="resarea">

			<template>
				
				<el-table  v-loading="loading" :data="tableData" border style="width: 100%"   >
					<el-table-column v-if="primary === 1" prop="area" label="所在区域">
					</el-table-column>
					<el-table-column v-if="primary === 2" prop="ym"  label="统计时间">
					</el-table-column>
					<el-table-column v-if="primary === 3" prop="usecate_name"   label="用途">
					</el-table-column>
					<el-table-column prop="total_acreage" sortable label="总面积(㎡)">
					</el-table-column>
					<el-table-column prop="total_buildacreage" sortable label="总建筑面积(㎡)">
					</el-table-column>
					<el-table-column prop="total_tradeprice" sortable label="总金额(万元)">
					</el-table-column>
					<el-table-column prop="floorprice" sortable label="楼面价(元/㎡)">
					</el-table-column>
					<el-table-column prop="overflow" sortable label="溢价率(%)">
					</el-table-column>
					<el-table-column prop="number" sortable label="宗数">
					</el-table-column>
				</el-table>
			</template>

		</div>
		<div v-if="mode === 2" class="morebar">
			<morebar :data="tableData" />
		</div>

	</div>
</template>

<script>
import morebar from '@/components/echarts/morebar'
import SortLayout from '@/components/common/sortLayout'
import { useCateList, citylist } from '@/api/data.js'
import dategroup from '@/components/common/dategroup.vue'
import { area, time, usecate ,areadown, timedown, usecatedown } from '@/api/Anylise'

export default {
	components: { dategroup, SortLayout, morebar },
	name: 'FrontendPcBigdataAnylise',
	data() {
		return {
			tableData: [
			],
			isvip:false,
			loading:true,
			primary: 1,
			mode: 1,
			index: 0,
			sorts: [
				['a', 'b', 'c']
			],
			citylist: [],
			usecate: [],
			condition: {
				dcity_ids: [],
				usecate: [],
				type: 1,
				start: '',
				end: '',
				usecate: '',
				afford: false,
			},

			cascaderProp: {
				multiple: true,
				emitPath: false,
				checkStrictly: true
			}


		}
	},

	watch: {
		primary: {
			handler(nv) {
				this.search()
			}
		}
	},

	mounted() {
		Promise.all(
			[
				//初始化城市区域列表 
				this.getBigdataCity(),

				// this.getCurrentAreaByID(this.shi),
				//初始化用途
				this.getUseCate(),
			]
		).then(res => {
			this.search()
		})
	},
	methods: {
		//会员中心
		toUcenter(v) {
			const usercenter = process.env.VUE_APP_LOGIN_BASIC
			window.location.href = usercenter + 'member/buyvip'
		},


		async search() {
			this.loading = true
			const params = {
				type: this.condition.type,
				dcity_ids: this.condition.dcity_ids.toString(),
				start: this.condition.start,
				end: this.condition.end,
				usecate: this.condition.usecate.toString(),
				afford: this.condition.afford ? 1 : 0,
			}
			let resdata = {}
			let header = []
			switch (this.primary) {
				case 1:
					resdata = await area(params)
					// header = ['总面积(㎡)', '总建筑面积(㎡)', '总金额(万元)', '楼面价(元/㎡)', '溢价率(%)', '宗数']
					break;
				case 2:
					resdata = await time(params)
					// header =  ['总面积(㎡)', '总建筑面积(㎡)', '总金额(万元)', '楼面价(元/㎡)', '溢价率(%)', '宗数']
					break;
				case 3:
					resdata = await usecate(params)
					// header = ['总面积(㎡)', '总建筑面积(㎡)', '总金额(万元)', '楼面价(元/㎡)', '溢价率(%)', '宗数']
					break;
			}
				this.tableData = resdata.data.table
				this.isvip = resdata.data.isvip
				this.loading = false
			
		},


		async down() {
			this.loading = true
			const params = {
				type: this.condition.type,
				dcity_ids: this.condition.dcity_ids.toString(),
				start: this.condition.start,
				end: this.condition.end,
				usecate: this.condition.usecate.toString(),
				afford: this.condition.afford ? 1 : 0,
			}
			let resdata = {}
			let header = []
			switch (this.primary) {
				case 1:
					resdata = await areadown(params)
					// header = ['总面积(㎡)', '总建筑面积(㎡)', '总金额(万元)', '楼面价(元/㎡)', '溢价率(%)', '宗数']
					break;
				case 2:
					resdata = await timedown(params)
					// header =  ['总面积(㎡)', '总建筑面积(㎡)', '总金额(万元)', '楼面价(元/㎡)', '溢价率(%)', '宗数']
					break;
				case 3:
					resdata = await usecatedown(params)
					// header = ['总面积(㎡)', '总建筑面积(㎡)', '总金额(万元)', '楼面价(元/㎡)', '溢价率(%)', '宗数']
					break;
			}
				const downurl = resdata.data
				this.loading = false

				// console.log('resdata',resdata)
				window.location.href = downurl
				// window.
		},






		typedate(d) {
			this.condition.type = d.type
			this.condition.start = d.start
			this.condition.end = d.end
		},

		changePrimary(e) {
			switch (e) {
				case 1:
					this.sorts = [
						['a', 'b', 'c']
					]
					break;
				case 2:
					this.sorts = [
						['b', 'a', 'c']
					]
					break;
				case 3:
					this.sorts = [
						['c', 'a', 'b']
					]
					break;
			}
		},

		changeMode() {

		},

		//获取大数据city
		getBigdataCity() {
			var that = this
			return new Promise(function (resolve, reject) {
				citylist().then(res => {
					that.citylist = res.data
					return resolve(res.data)
				})
			})
		},


		//获取用途
		getUseCate() {
			var that = this
			return new Promise(function (resolve, reject) {
				useCateList().then(res => {
					that.condition.usecate = [res.data[0].id, res.data[1].id, res.data[2].id, res.data[3].id]  //获取第一个为默认
					that.usecate = res.data
					return resolve(res.data)
				})
			})
		},

	}


};
</script>

<style lang="scss" scoped>
.morebar {
	width: 100%;
}

.current {
	border: solid 1px #28A7E1;
	background: #28A7E1;
	color: #fff;
}

.labeltitle {
	height: 22px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 22px;
	margin-right: 20px;
}

.changeRes {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 98%;
	margin-top: 10px;
	margin-left: 10px;
}

.resarea {
	width: 98%;
	margin-top: 10px;
	margin-left: 10px;

}

.actionarea {
	width: 98%;
	margin-top: 20px;
	margin-left: 10px;
	height: 108px;
	background: #F0F3F8;
	border-radius: 4px;
	display: flex;

	.compare {
		width: 90%;
		// background: red;
		padding: 0 20px;

		.el-form {
			width: 100%;
		}

		.sortarea {
			width: 100%;
			display: flex;
			flex-flow: wrap;
			height: 108px;
		}

	}

	.chaxunbtn {
		width: 10%;
		display: flex;
		flex-direction: center;
		align-items: center;

	}
}

.quyu {
	width: 360px;
	height: 54px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-right: 20px;
}

.yongtu {
	width: 500px;
	height: 54px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-right: 20px;

	.inneryongtu {
		display: flex;
	}

	.morechecked {
		width: 370px;
		display: flex;
		justify-content: flex-start;
		align-content: center;

	}

	.kaiguan {
		width: 120px;
	}
}

.shijian {
	display: flex;
	height: 54px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-right: 20px;

}

::v-deep .div__label {
	font-size: 16px;
	color: #000;
}

::v-deep .div {
	margin: 0;
}
</style>