<template>
	<div>
		<el-button size="mini" @click="showDialoag = true">表单选择</el-button>

		<el-dialog width="80%" style="zIndex:100000" title="选择表单需要需要展示的表头" :visible.sync="showDialoag">
			<el-form >
				<el-form-item>
					<el-checkbox-group v-model="checkList">
						<el-checkbox  style="width:180px;text-align: left;" v-for="item in alldata" :key="item.field"  :label="item.field">
							{{ item.name }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="toSubmit">确 定</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
export default {
	name: 'ProjectSelectForm',
	// model: {
	// 	prop: 'checkeddata',
	// 	event: 'changedata'
	// },
	data() {
		return {
			showDialoag: false,
			checkList: []
		};
	},

	props: {
		alldata: {
			type: Array,
			default: function () {
				return []
			}
		}
	},

	watch: {
		alldata: {
			immediate: true, //初始化时让handler调用一下
			handler(newValue, oldValue) {
				const checkList = []
				newValue.filter(item => {
					if (item.checked) {
						checkList.push(item.field)
					}
				})
				this.checkList = checkList
			}
		},
		checkList: {
			handler(newValue, oldValue) {
				this.$emit('changedata',newValue)
				
			}
		}


	},

	mounted() {

	},
	methods: {
		toSubmit(){
			this.$emit('complate',this.checkList)
			this.showDialoag = false

		}
		
	}


};
</script>

<style lang="scss" scoped>
.el-dialog__header{
	text-align: left;
}
.el-checkbox-group{
	display: flex;
	flex-wrap: wrap;
}
</style>