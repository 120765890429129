<template>
  <transition name="fade">
    <div class="dialog-container" v-if="visible">
      <!-- 遮罩层 -->
      <div class="dialog-mask" @click="handleMaskClick"></div>

      <!-- 弹出框内容 -->
      <div class="dialog-content" :style="{ transform: 'translate(-0%, -0%)' }">

        <div class="popbox">
          <div class="topbox" >
            <div class="left">
              <div class="huodongjia">活动价</div>
              <div class="price"><span class="red">￥</span>{{ viplist.activity_price }}</div>
            </div>
            <div class="right">
              <div class="top">截止时间：{{ viplist.deadline }}</div>
              <div class="bottom">不限次数查看成交数据</div>
            </div>
          </div>
          <div class="botbox">
            <div class="title">注：仅用于查看成交数据，如需下载数据请<span style="color:#00A3E4;cursor: pointer;font-weight:bold;"  @click="toUcenter">开通超值数据包</span></div>
            <div class="con">
              <div :class="{ 'item': true, 'current1': selectcurrent === 2 }"
                @click="changeprice(viplist.list[0].id)">
                <div class="label">{{ viplist.list[0].description }}</div>
                <div class="coninner">
                  <div class="pricetext">{{ viplist.list[0].name }}</div>
                  <div class="pricebox">
                    <div class="yang">¥</div>
                    <div class="price">{{ viplist.list[0].price }}</div>
                  </div>
                </div>
              </div>
              <div :class="{ 'item': true, 'current1': selectcurrent === 1 }"
                @click="changeprice(viplist.list[1].id)">
                <div class="label">{{ viplist.list[1].description }}</div>
                <div class="coninner">
                  <div class="pricetext">{{ viplist.list[1].name }}</div>
                  <div class="pricebox">
                    <div class="yang">¥</div>
                    <div class="price">{{ viplist.list[1].price }}</div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="tehui" @click="toUcenter">
              <img class="sound" src="@/assets/icon/sound.png" alt="">
              <div class="v2text">
                 <div class="text1"> 特惠活动：开通任意超值数据包，可免费获赠1年会员</div>
                 <div class="text2">详情 <span>了解更多</span></div>
              </div>
            </div>

            <pay-button
              :params="{
                goods_id: 12,
                condition: {
                  'bigdata_landdata_vip_id':selectcurrent
                }	}" :text="text" @success="paySuccess" text="支付" size="normal"></pay-button>

    

          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

import { bigdatavip } from '@/api/member.js'

import PayButton from '@/components/down/PayButton.vue';


export default {
  name: 'CustomDialog',
  components:{PayButton},
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      maskClosable: true, // 是否可以通过点击遮罩层关闭对话框，默认为true
      viplist: {
        activity_price:'',
        list:[],

      },
      selectcurrent: null,
    }
  },
  mounted() {
    document.body.classList.add('prevent-scroll');
    this.getdata()
  },
  beforeUnmount() {
    document.body.classList.remove('prevent-scroll');
  },
  methods: {


    toUcenter(v) {
			const usercenter = process.env.VUE_APP_LOGIN_BASIC
			window.location.href = usercenter + 'member/buyvip'
		},

    paySuccess() {
        this.$message.success('您已经付费成功,页面正在刷新中...')
				window.location.reload()
		},

    topay(){

    },

    changeprice(id) {

      this.selectcurrent = id
      console.log( this.selectcurrent )
    },

    getdata() {
      bigdatavip().then(res => {
        this.viplist = res.data
        this.selectcurrent = this.viplist.list[0].id
      })
    },
    close() {
      this.$emit('update:visible', false);
    },
    confirm() {
      // 可以在这里添加确认逻辑
      this.close();
    },
    handleMaskClick() {
      if (this.maskClosable) { // 判断是否允许点击遮罩层关闭对话框
        // this.close();
      }
    }
  },

};
</script>

<style scoped lang="scss">
  .tehui{
    margin-top:20px;
    display: flex;
    align-items: center;
    .sound{
      height:16px;
      width:16px;
      margin-right:10px;
    }
    .v2text{

      display: flex;
      flex-direction: column;
      cursor: pointer;
      justify-content: center;
      .text1,.text2{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #FF0000;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        span{
          text-decoration:underline;
        }
      }
    }
  }

 ::v-deep .el-button--primary  {

      margin-top: 20px;
      width: 338px;
      height: 60px;
      background: #F1221F;
      border-color:#F1221F;
      border-radius: 6px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 60px;
      text-align: center;
      font-style: normal;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    
}

// el-button--primary el-button--normal

.item {
  width: 160px;
  height: 106px;
  background: #FFFFFF;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;

  .label {
    width: 100px;
    height: 28px;
    background: #F1221F;
    border-radius: 6px 0px 6px 0px;
    position: absolute;
    top: -6px;
    left: -6px;
    z-index: 2;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 28px;
    text-align: center;
    font-style: normal;
    overflow: hidden;
  }

  .coninner {

    border: 1px solid #E4E4E4;
    width: 150px;
    height: 106px;

    border-radius: 6px;
    background: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .pricetext {
      margin-top: 10px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #000000;
      line-height: 25px;
      text-align: center;
      font-style: normal;
    }

    .pricebox {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .yang {
        font-size: 15px;
        color: #EB0000;
        position: relative;
        top: -5px;
      }

      .price {

        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 30px;
        color: #EB0000;
        text-align: center;
        font-style: normal;
      }
    }
  }


}

.current1 {
  .coninner {

    background: rgba(241, 34, 31, 0.1);
  }
}

.dialog-container {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 半透明遮罩层颜色 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(6, 6, 6, 0.3);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-content {
  padding-top: 340px;

  .popbox {

    width: 400px;
    /* 自定义弹窗宽度 */
    height: 380px;
    /* 自定义弹窗最小高度 */
    background-color: #fff;
    border-radius: 4px;

    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    .topbox {
      width: 360px;
      height: 135px;
      background: url('https://bigdata.chinatupai.com/ministatic/bigdata/redtitlebg.png') no-repeat;
      background-size: 100% 100%;
      margin-left: 20px;
      position: relative;
      top: -54px;
      display: flex;

      .left {
        margin-left: 10px;
        width: 104px;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .huodongjia {
          margin-top: 10px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 22px;
          color: #FFFFFF;
          line-height: 30px;
          text-align: center;
          font-style: normal;
        }

        .price {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 42px;
          color: #FFFFFF;

          text-align: center;
          font-style: normal;

          .red {
            font-size: 18px;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        width: 100%;

        .top {
          margin-top: 46px;
          margin-left: 30px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 22px;
          text-align: center;
          font-style: normal;
        }

        .bottom {
          margin-top: 12px;
          margin-left: 20px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 22px;
          color: #FFFFFF;
          line-height: 30px;
          text-align: center;
          font-style: normal;
        }
      }
    }

    .botbox {
      position: relative;
      top: -54px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;




    }

    .title {
      margin-top: 5px;
      margin-bottom: 18px;
      width: 340px;
      height: 17px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #747474;
      line-height: 17px;
      text-align: left;
      font-style: normal;
    }

    .con {
      width: 340px;
     
      display: flex;
      justify-content: space-between;
      align-items: center;


    }

    .btn {
      margin-top: 30px;
      width: 338px;
      height: 60px;
      background: #F1221F;
      border-radius: 6px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 60px;
      text-align: center;
      font-style: normal;
      cursor: pointer;
    }

  }
}
</style>