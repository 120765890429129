<script>
/**
 *  排序布局
 *  @param  {String}  tag   包囊元素标签
 *  @param  {Array}   sort  元素插入顺序 ( Array[String: 按slot名称映射] | Array[{ slot <String>: 按slot名称映射, scope <*>: 作用域映射值 }])
 */
export default {
  name: 'SortLayout',
  functional: true,
  inheritAttrs: false,
  props: {
    tag: { type: String, default: 'span' },
    sort: { type: Array, default: void 0 }
  },
  render(h, { data, props: { tag, sort }, scopedSlots }) {
    let content = []
    if (Array.isArray(sort)) {
      for (const item of sort) {
        const isObj = typeof item == 'object' && item
        const slot = isObj ? item.slot : item
        const scope = isObj ? item.scope : void 0
        const node = slot != null && slot !== false && scopedSlots[slot] ? scopedSlots[slot](scope) : void 0
        if (node) Array.isArray(node) ? content.push(...node) : content.push(node)
      }
    } else if (scopedSlots.default) {
      content = scopedSlots.default()
    }
    return h(tag, data, content)
  }
}
</script>
