<template>
  <bm-overlay
    ref="customOverlay"
    :class="{sample: true, active}"
    pane="labelPane"
    @draw="draw">
    <div v-text="text" @click="handleClick(data)"></div>
  </bm-overlay>
</template>

<script>
export default {
	data(){
    return {
      map:null,
    }
	
	},
  props: ['text', 'position', 'active','data'],
  watch: {
    position: {
      handler () {
        this.$refs.customOverlay.reload()
      },
      deep: true
    }
  },
  methods: {
    handleClick (data) {
			this.$emit('setZoom',data)
    },

    draw ({el, BMap, map}) {
			if(this.map!==null){
				this.map = map
			}
      const {lng, lat} = this.position
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat))
      el.style.left = pixel.x - 60 + 'px'
      el.style.top = pixel.y - 20 + 'px'
    }
  }
}
</script>

<style>


.sample {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: rgba(7, 113, 212);
  overflow: hidden;
  box-shadow: 0 0 5px #000;
  color: #fff;
  text-align: center;
  padding: 10px;
	border-radius: 50%;
  position: absolute;
}
.sample.active {
  background: rgba(7, 113, 212);
  color: #fff;
}
</style>