<template>


	<div class="tablediv">
		<el-table ref="tableName" @sort-change="sortchange" :data="data.tableData"
			:header-cell-style="{ background: '#eef1f6', color: '#606266' }" @cell-click="tomap" v-if="tableHeight"
			:height="tableHeight" :cell-style="cellstyle" :row-style="{height: '0'}"
			:default-sort="{ prop: 'sheng', order: 'descending' }">

			<el-table-column v-for="item in data.headerData"  v-if="item.field!=='id'" :key="item.field" :sortable="item.sortable" :width="item.width"
				:prop="item.field" :label="item.name">
			</el-table-column>

			<el-table-column fixed="right" width="120" class-name="leave-alone" label="土地资料">
				<template slot-scope="scope">
					<div leave-alone style="width:120px">
						<el-button @click="showList(scope.row)" size="small">下载<i class="el-icon-download el-icon--right"></i>
						</el-button>
					</div>
				</template>
			</el-table-column>
			
		</el-table>


		<el-dialog title="资料清单" width="30%" :visible.sync="listVisible">
			<div v-if="hasfile">

				<el-checkbox class="checkAll" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
				<div class="listbox">

					<el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
					<div class="linebox" v-for="item in listdata.list">
						<el-checkbox  :label="item.file_ids" :key="item.file_ids">
    					<div class="line">
							<div class="left">
								{{ item.name }} x {{ item.amount }}</div>
							<div class="right">{{ item.price }} 元</div>
						</div>
					</el-checkbox>
					</div>
				</el-checkbox-group>
				</div>
				<div class="payshow">
					<div class="paytitle">支付金额:</div>
					<div class="yuan">{{ countprice }} 元</div>
				</div>
				<div class="paybtn">
					<download-button size="small" icon="pics" :params="{
						goods_id: 6,
						condition: { pid: currentdataid ,file_ids:fileIds}
					}" :text="actiontext" :goDownload="goDownload" @colsedialog="colsedialog"></download-button>
				</div>

			</div>
			<div v-if="!hasfile">
				<el-empty description="对不起,没有找到需求下载的资料～"></el-empty>
			</div>

		</el-dialog>


	</div>
</template>
<script>

import { fileDownList } from '@/api/orderpay.js'
import { downloadZip } from '@/api/data'

import Cookies from 'js-cookie'

import DownloadButton from "@/components/down/DownloadButton.vue"
export default {
	name: 'ProjectTableData',
	components: {
		DownloadButton
	},
	computed: {
		fileDownList
	},
	data() {
		return {
			userinfo: Cookies.get('userinfo'),
			currentdataid: 0,
			hasfile: true,
			listVisible: false,
			goDownload: false,
			actiontext: '去支付',
			tableHeight: '',
			countprice:null,
			listdata: [

			],
			checkedCities:[

			],
			checkAll: true,

			fileIds:'',

			isIndeterminate:false	
		}
	},
	props: {
		data: {
			type: Object,
			default: function () {
				return {}
			}
		}
	},
	watch: {
		data: {
			handler(v) {
				this.$nextTick(() => {
					this.$refs.tableName.doLayout();
					// el-table添加ref="tableName"
				});
			}
		}

	},
	created() {
		this.getTableHeight();
	},

	mounted() {

		//挂载window.onresize事件(动态设置table高度)
		let _this = this;
		window.onresize = () => {
			if (_this.resizeFlag) {
				clearTimeout(_this.resizeFlag);
			}
			_this.resizeFlag = setTimeout(() => {
				_this.getTableHeight();
				_this.resizeFlag = null;
			}, 100);
		};
	},

	methods: {

		colsedialog(){
				this.listVisible = false
		},

		handleCheckAllChange(val) {
        this.checkedCities = val ? this.listdata.list.map(item=>item.file_ids): [];
        this.isIndeterminate = false
				this.countTotalPrice()
				this.countFileIds()
		
    },
		
    handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.listdata.list.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.listdata.list.length;
				this.countTotalPrice()
				this.countFileIds()
    },

		countTotalPrice(){
			let total = 0
			this.listdata.list.map(item=>{
					if(this.checkedCities.indexOf(item.file_ids) > -1){
						total = total + item.price
					}
			})
			
			// console.log('------',this.userinfo)

			if(this.userinfo.isvip){
				this.countprice = total	
			}else{
				this.countprice = 0
			}

		
		},

		countFileIds(){
	
			this.fileIds = this.checkedCities.toString()
	
		},

		

		sortchange(e) {
			this.$emit('sortchange', e)
		},
		//计算table高度(动态设置table高度)
		getTableHeight() {
			let tableH = 310; //距离页面下方的高度
			let tableHeightDetil = window.innerHeight - tableH;
			if (tableHeightDetil <= 300) {
				this.tableHeight = 300;
			} else {
				this.tableHeight = window.innerHeight - tableH;
			}
		},

		/**
 * 使用span标签包裹内容，然后计算span的宽度 width： px
 * @param valArr
 */
		getTextWidth(str) {
			let padding = 0;//单元格左右padding距离
			let width = 0;
			let span = document.createElement('span');
			span.innerText = str;
			span.className = 'getwidth';
			document.querySelector('body').appendChild(span);
			// 这里可以获取当前单元格的font-size 以及 内容的中英文、字母等  做更精确的计算
			width = document.querySelector('.getwidth').offsetWidth + padding;
			document.querySelector('.getwidth').remove();
			return width;
		},

		/**
		* 遍历列的所有内容，获取最宽一列的宽度
		* @param {Array} arr 需要计算的数据
		* @param {Number} minwidth 列最小宽度
		*/
		getMaxLength(arr, minwidth = 60) {
			return arr.reduce((acc, item) => {
				if (item) {
					let calcLen = this.getTextWidth(item);
					if (acc < calcLen) {
						acc = calcLen;
					}
				}
				return acc < minwidth ? minwidth : acc;
			}, 0)
		},

		/**
		* @description 计算列表列宽（把内容撑开）
		* @param {Array} columns 列的数组
		* @param {Array} tableArr 列表的数组
		* */
		calcColumnsWidth(columns, tableArr) {
			columns.forEach((item) => {
				const arr = tableArr.map((x) => x[item.props]);
				item.width = getMaxLength(arr);
				arr.push(item.label); // 把每列的表头也加进去算
			});
			return columns;
		},



		goPay() {
			this.listVisible = false
		},
		//
		showList(row) {

			// row.id = 25005
			// fileDownList({ pid: row.id }).then(res => {
			fileDownList({ pid: row.id }).then(res => {
				this.currentdataid = row.id

				this.countprice = res.data.total

				const list = res.data.list
				if (list && list.length > 0) {
					this.listdata = res.data
					this.checkedCities = res.data.list.map(item=>item.file_ids)
					this.fileIds = res.data.list.map(item=>item.file_ids).toString()
	
					if (res.data.total === 0) {
						this.goDownload = true
						this.actiontext = "下载"
					} else {
						this.goDownload = false
						this.actiontext = "去支付"
					}
					this.hasfile = true
					this.listVisible = true


				} else {
					this.goDownload = false
					this.actiontext = "去支付"
					this.hasfile = false
					this.listVisible = true
				}
			})

		},
		//导出压缩文件
		exportZip(id) {
			const params = {
				id: id,
				tableHeader: JSON.stringify(this.headerData)
			}
			downloadZip(params).then(res => {
				window.location.href = res.data.url
			})
		},

		//设置获取跳转到地图的id
		tomap(row, column, cell, event) {
			// console.log(row, column, cell, event)
			if (column.property === 'location' && row.hasmap === 1) {
				const id = row.id
				this.$emit('tolocation', id)
			}


		},

		//设置样式
		cellstyle(row) {
			if (row.column.property === 'location' && row.row.hasmap === 1) {
				return 'color:#409EFF;cursor:pointer;padding:2px'
			} else {
				return 'padding:2px'
			}
		}



	}

}
</script>

<style lang="scss" scoped>
// .主class .el-table__fixed-right {

// }

::v-deep .el-table .el-table__fixed-right {
	width: 200px;
	height: 100%;
}

.checkAll{
	// background:red;
	position: relative;
	left:calc(-50% + 60px);
}

.fixedright {
	width: 200px;
}

.listbox {
	height: auto;
	max-height: 300px;

	overflow-x: hidden;
	overflow-y: scroll;
}

.payshow {
	display: flex;
	justify-content: center;
	align-items: center;

	// background:red;
	.paytitle {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		text-shadow: 0px 5px 26px rgba(0, 0, 0, 0.2);
	}

	.yuan {
		font-size: 20px;
		color: #df2020;
		font-weight: bold;
	}
}

.paybtn {
	margin-top: 10px;
}

.linebox {
	height: 40px;
	line-height: 40px;
	padding: 0 20px;

	.left,
	.right {
		height: 22px;
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #000000;
		line-height: 22px;
		text-shadow: 0px 5px 26px rgba(0, 0, 0, 0.2);
	}
}

.line {
	width:300px;
	display: flex;
	justify-content: space-between;
	align-content: center;
	line-height: 28px;
	border-bottom: solid 1px #efefef;

}

::v-deep .el-table .cell {
	// white-space: nowrap;
	// // overflow: ;
	// overflow: hidden;
	// text-overflow: ellipsis;
	// // display: inline-block;


	// overflow: hidden;
	// white-space: nowrap;
	// text-overflow: ellipsis;
}

.tablediv {
	margin-top: 10px;
}
</style>