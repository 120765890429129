<template> 
  <div class="pageContainer">
    <div class="leftmap" id="mapcon">

      <div class="plugin">
          <div :class="{'pbtn':true, 'activebtn':'vector'=== currentMapLayer.type}" @click="toggleMapType('vector')">矢量地图</div>
          <div :class="{'pbtn':true, 'activebtn':'satellite'=== currentMapLayer.type}" @click="toggleMapType('satellite')">影像地图</div>
      </div>
     

      <l-map
        ref="map"
        style="width: 100%; height: 700px; z-index: 1"
        :zoom="zoom"
        :center="centerpoint"
      >
        

        
         <l-tile-layer :url="currentMapLayer.url" ></l-tile-layer>
         <l-tile-layer :url="currentMapLayer.texturl" :attribution="currentMapLayer.attribution"></l-tile-layer>
        <!-- <l-tile-layer :url="yunxuanurl" ></l-tile-layer> --> 

		    <l-polygon v-for="polygon in polygonPath"  :lat-lngs="polygon.path" 
         :fill-color="polygon.filecolor" :fill-opacity="polygon.fillopacity" 
          :visible="polygon.hide"
          :color="polygon.filecolor"></l-polygon>
        <l-marker
          v-for="item in areaCountData"
          :key="item.id"
          :lat-lng="[item.lat_gaode, item.lng_gaode]"
          @click="toZoom({lat: item.lat_gaode, lng: item.lng_gaode})"
         
        >
          <l-icon :icon-anchor="staticAnchor">
            <div class="yuan">
              {{ item.count }}
            </div>
          </l-icon>
        </l-marker>

        <l-marker
        
          v-for="item in markerdata"
          :key="item.id"
          :icon="icon(item.usecate)"
          :lat-lng="[item.lat_gaode, item.lng_gaode]"
          :offset="[8, 0]"
          :visible = "item.hide"
        >
          <!-- permanent: true // 如果想要提示永久显示，可以设置为true -->
          <l-tooltip
            :options="{
              permanent: true,
              interactive: true,
              offset: [4, -16],
              className: 'color' + item.usecate,
            }"
           
          >
            <div class="title">
              {{
                item.locationnum ||
                item.noticenum ||
                item.locationname ||
                item.location
              }}
            </div>
          </l-tooltip>
          <l-popup   >
            <div class="detailbox">
              <div class="tltlecon">地块详情</div>
              <div class="line">
                <div class="left">省/市/区县</div>
                <div class="right">{{ item.ssq }}</div>
              </div>
              <div class="line">
                <div class="left">公告号</div>
                <div class="right">{{ item.noticenum }}</div>
              </div>
              <div class="line">
                <div class="left">宗地编号</div>
                <div class="right">{{ item.locationnum }}</div>
              </div>
              <div class="line">
                <div class="left">用途</div>
                <div class="right">{{ item.usecatename }}</div>
              </div>
              <div class="line">
                <div class="left">面积(㎡)</div>
                <div class="right">{{ item.acreage }}</div>
              </div>
              <div class="line">
                <div class="left">建筑面积(m*)</div>
                <div class="right">{{ item.buildacreage }}</div>
              </div>
              <div class="line">
                <div class="left">容积率</div>
                <div class="right">{{ item.volume }}</div>
              </div>

              <div class="line">
                <div class="left">竟得单位</div>
                <div class="right">{{ item.company }}</div>
              </div>
              <div class="line">
                <div class="left">挂牌价格</div>
                <div class="right">{{ item.totalsp }}</div>
              </div>
              <div class="line">
                <div class="left">成交总价</div>
                <div class="right">{{ item.tradeprice }}</div>
              </div>
              <div class="line">
                <div class="left">地块位置</div>
                <div class="right">{{ item.location }}</div>
              </div>
              <div class="line">
                <div class="left">成交日期</div>
                <div class="right">{{ item.tradedate }}</div>
              </div>
              <div class="line">
                <div class="left">楼面价</div>
                <div class="right">{{ item.floorprice }}</div>
              </div>
              <div class="line">
                <div class="left">溢价率</div>
                <div class="right">{{ item.overrage }}</div>
              </div>
              <div class="more">
                <div class="btn" @click="tozogndi(item.id)">查看更多</div>
              </div>
            </div>
          </l-popup>
        </l-marker>
      </l-map>
    </div>

    <div class="rightmap">
      <div class="listtitle">宗地列表</div>
      <div class="listbox">
        <div class="list">
          <!-- <el-checkbox v-model="checked">备选项</el-checkbox> -->

          <div v-if="less100">
            <el-checkbox
              checked
              :class="{
                listitem: true,
                current: item.id === currentSelectItemID,
              }"
              v-for="item in rightList"
              @change="toMapPoint(item)"
              :key="item.id"
              >{{ item.title }}</el-checkbox
            >
          </div>
          <div
            v-else
            v-for="item in rightList"
            :key="item.id"
            :class="{
              listitem: true,
              current: item.id === currentSelectItemID,
            }"
            @click="toMapPoint(item)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolygon,
  LIcon,
  LTooltip,
  LLayerGroup,
  LPopup,
} from "vue2-leaflet";
import { latLng, icon } from "leaflet";
import { vectorMapLayer,satelliteMapLayer,leafletmapurl,leafletmaptexturl,leafletmapyunxuanurl, isgaode } from "@/settings.js";
import { polygonData } from "@/api/cityresearch";

import { BmlMarkerClusterer } from "vue-baidu-map";

import MyOverlay from "@/components/common/MyOverlay.vue";

export default {
  props: {
    center: {
      type: Object
    },
    mapdata: {
      type: Array,
      default: function () {
        return [];
      },
    },
    singledata: {
      type: Object,
      default: function () {
        return {};
      },
    },
    areaCountData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    polygondata: {
      type: Array,
      default: function () {
        return [];
      },
    },
    //搜索数据
    searchdata: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  data() {
    return {
      caller: null,
      staticAnchor: [16, 37],
      iconSize: 64,
      polygonArr: [],
      zoom: 10,
      url: leafletmapurl,
      texturl:leafletmaptexturl,
      yunxuanurl:leafletmapyunxuanurl,
      //矢量地图对象
       vectorMapLayer:vectorMapLayer ,
      //卫星地图数据对象
      satelliteMapLayer: satelliteMapLayer,
      //当前地图图层
      currentMapLayer:{url:'',texturl:'',attribution:'',type:'vector'},

      attribution: "",
      marker: L.latLng(18.212516, 109.482965),
      text: "this is a marker",
      showParagraph: false,

      active: true,
      styles: [
        // url, size, opt_anchor, textColor, opt_textSize
        {
          textColor: "#f00",
          size: 20,
          opt_anchor: "#f00",
        },
      ],
      infopoint: {},
      polygonPath: [],
      infoWindowData: {}, //当前点击的info信息数据
      infoWindow: {
        show: false,
        contents: "",
        title: "",
      },

      strokeWeight: 2, // 边框宽
      strokeOpacity: 1, // 线透明度
      fillOpacity: 0.6, // 透明度
      markers: [],
      visible: true,

      map: {},
      BMap: {},
      geodata: "",

      centerpoint: { lat: 0, lng: 0 },
      count: 1,
      // zoom: 12,
      polygonsIDArr: [], //已经生成的多变形的id数组
      markersIDArr: [], //已经生成的点的id数组
      //本次的markers
      currentMarkersIDArr: [],
      currentPolygonsIDArr: [],
      //本次新增的id
      lastCurrentMarkersAdd: [],
      lastCurrentPolygonsAdd: [],
      //记录上一次的markers数组
      lastMarkersIDArr: [],
      lastPolygonsIDArr: [],
      rightList: [],
      currentSelectItemID: {}, //当前点击的项目ID
      showClusterer: true, //是否显示聚合
      clustererdata: [],

      markerdata: [],
      showPloygon: false,
      where: {
        // currentZoom: 8,
        // dateValue: [new Date('2021-01-01').format('yyyy-MM-dd'), new Date().format('yyyy-MM-dd')], // 默认日期
        ll_lng: "", // 经度 左下角
        ll_lat: "", // 纬度 左下角
        ru_lng: "", // 经度 右上角
        ru_lat: "", // 纬度 右上角
      },
      less100: false,
      searchinfodata: null,
    };
  },

  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },

    currentzoom: {
      get() {
        return this.$store.state.currentzoom;
      },
      set(v) {
        this.$store.commit("currentzoom", v);
      },
    },
  },
  components: {
    BmlMarkerClusterer,
    MyOverlay,
    LMap,
    LTileLayer,
    LMarker,
    LPolygon,
    LIcon,
    LTooltip,
    LLayerGroup,
    LPopup,
  },
  beforeDestroy() {
    if (this.$refs.map.mapObject) {
      this.$refs.map.mapObject.off("zoomend", this.handleZoomEnd);
      this.$refs.map.mapObject.off("moveend", this.handleZoomEnd);
    }
  },
  created() {
     this.currentMapLayer = this.vectorMapLayer;
     this.currentMapLayer.type = 'vector'
  },
  mounted() {
    this.$refs.map.mapObject.on("zoomend", this.handleZoomEnd);
    this.$refs.map.mapObject.on("moveend", this.handleZoomEnd);
    // console.log("mounted",this.center)
    // this.centerpoint = this.center
    this.$nextTick(() => {
      this.handleZoomEnd();
    });
  
  },

  watch: {
    searchdata: {
      handler(data, ov) {
        // console.log(" watch searchdata1", data);
        if (data.length === 1) {
          //搜索的
          const newcenter = L.latLng(data[0].lat_gaode, data[0].lng_gaode);


          this.$refs.map.mapObject.setView(newcenter, 18);
           const marker = this.getMarkerById(data[0].id);
            if (marker) {
              // 在这里处理marker对象
              console.log('---marker OBJ---', marker);
            }

          // this.center = { 
          //   lng: data[0].lat_gaode,
          //   lat:data[0].lng_gaode
          // }
          // this.$emit("singlechangecenter", newcenter);
          // this.markerclick(data[0]);
        }
        // console.log(" watch searchdata2", this.centerpoint);
      },
      deep: true,
    },

    center: {
      handler(nv, ov) {

        if (nv.lng !== 0 && nv.lat !== 0) {
    
      
             this.centerpoint = {
                lng: nv.lng,
                lat: nv.lat,
              };

              // this.$nextTick(() => {
              //   this.handleZoomEnd();
              // });
        
         
        }

        console.log(" watch center", this.centerpoint);
      }
    },

    singledata: {
      handler(data, ov) {
        console.log(" watch singledata", data);
        this.singleData(data);
      },
      deep: true,
    },

    mapdata: {
      handler(nv, ov) {
        this.markerdata = nv
        this.handerPolygondata(nv);

        this.handlerBaiduData(nv);
      },
      deep: true,
    },
    where: {
      handler(nv, ov) {
        this.$emit("bounds", nv);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    toggleMapType(type) {
      if (type === 'vector') {
        this.currentMapLayer = this.vectorMapLayer;
        this.currentMapLayer.type = 'vector'
      } else if (type === 'satellite') {
        this.currentMapLayer = this.satelliteMapLayer;
        this.currentMapLayer.type = 'satellite'
      }
    },



    toZoom(centerpoint){
      this.$refs.map.mapObject.setView(centerpoint, 11);
    },

    getMarkerById(markerId) {
      return this.$refs.map.mapObject.eachLayer(layer => {
        if (layer._leaflet_id === markerId) {
          return layer;
        }
      });
    },



    tozogndi(id) {
      this.$router.push("/data/zhibiao/" + id);
    },

    openPopUp() {
      console.log("1212312");
      // this.caller = "1111";
      // this.$refs.features.mapObject.openPopup(latLng);
    },

    icon(num) {
      return icon({
        iconUrl: require("@/assets/type" + num + ".png"),
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      });
    },

    handleZoomEnd() {
      // 缩放和移动事件处理逻辑
      this.currentzoom = this.$refs.map.mapObject.getZoom();

      this.getMapBounds();
    },

    getMapBounds() {
      const map = this.$refs.map.mapObject; // 获取地图实例
      const bounds = map.getBounds(); // 获取当前视图的边界
      console.log(bounds); // 打印边界信息
      // 如果需要经纬度值，可以使用bounds对象的_northEast和_southWest属性
      const northEast = bounds._northEast;
      const southWest = bounds._southWest;
      //获取边界条件
      this.where = {
        ll_lng: southWest.lng, // 经度 左下角
        ll_lat: southWest.lat, // 纬度 左下角
        ru_lng: northEast.lng, // 经度 右上角
        ru_lat: northEast.lat, // 纬度 右上角
      };

      console.log("where", this.where);
    },

   

  handerPolygondata(polygondata) {
    let polygonpath = [];
    this.handleListData(polygondata);

    const pointobj = polygondata.map((item) => {
      const allset = item.set_gaode; //所有多边形
      if (item.set_gaode) {
        const setObj = JSON.parse(allset);
        const rowpolygonpath = setObj.map((itemSingle) => {
          // data 是一个多变形的数据
          // 处理一个多变形的方法
          const singlepath = this.handleSingleData(item.id, itemSingle);
          polygonpath.push(singlepath);
        });
      }
    });
    console.log("-polygonPath---", polygonpath);
    this.polygonPath = polygonpath;
  },

  setzoom(data) {
    this.centerpoint = {
      lng: data.lng,
      lat: data.lat,
    };

    this.currentzoom = 11;
  },

  //marker点击事件
  markerclick(centerpoint){
      this.$refs.map.mapObject.setView(centerpoint, 17);
  },

  infoWindowClose(e) {
    this.infoWindow.show = false;
  },

  infoWindowOpen(e) {
    this.infoWindow.show = true;
  },

  bd09_to_gaode(bd_lon, bd_lat) {
			var X_PI = Math.PI * 3000.0 / 180.0;
			var x = bd_lon - 0.0065;
			var y = bd_lat - 0.006;
			var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
			var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
			var gg_lng = z * Math.cos(theta);
			var gg_lat = z * Math.sin(theta);
			return [gg_lng, gg_lat];
	},

   BD09II2WGS84(bd_lon, bd_lat) {
      var x_pi = 3.14159265358979324 * 3000.0 / 180.0;
      //百度坐标转成火星坐标
      var mars_point = { lat: 0, lon: 0,};
      var x = bd_lon - 0.0065;
      var y = bd_lat - 0.006;
      var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
      var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
      mars_point.lat = z * Math.sin(theta);
      mars_point.lon = z * Math.cos(theta);

      //把火星坐标GCJ02转地球坐标系WGS84
      var gcjLat =  mars_point.lat;
      var gcjLon = mars_point.lon;
      let d = this.delta(gcjLat, gcjLon)
    //  return {
    //      'lat': ( gcjLat - d.lat ).toFixed(5),
    //      'lon': ( gcjLon - d.lon ).toFixed(5)
    //  }
    return [( gcjLon - d.lon ).toFixed(5), ( gcjLat - d.lat ).toFixed(5)];

  },


    delta(lat, lon) {
      var PI = 3.14159265358979324;
      let a = 6378245.0 
      let ee = 0.00669342162296594323 
      let dLat = this.transformLat(lon - 105.0, lat - 35.0)
      let dLon = this.transformLon(lon - 105.0, lat - 35.0)
      let radLat = lat / 180.0 * PI
      let magic = Math.sin(radLat)
      magic = 1 - ee * magic * magic
      let sqrtMagic = Math.sqrt(magic)
      dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * PI)
      dLon = (dLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * PI)
      return {
          'lat': dLat,
          'lon': dLon
      }
    },
      transformLat(x, y) {
          var PI = 3.14159265358979324;
          let ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x))
          ret += (20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0 / 3.0
          ret += (20.0 * Math.sin(y * PI) + 40.0 * Math.sin(y / 3.0 * PI)) * 2.0 / 3.0
          ret += (160.0 * Math.sin(y / 12.0 * PI) + 320 * Math.sin(y * PI / 30.0)) * 2.0 / 3.0
          return ret
      },
      transformLon(x, y) {
          var PI = 3.14159265358979324;
          let ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x))
          ret += (20.0 * Math.sin(6.0 * x * PI) + 20.0 * Math.sin(2.0 * x * PI)) * 2.0 / 3.0
          ret += (20.0 * Math.sin(x * PI) + 40.0 * Math.sin(x / 3.0 * PI)) * 2.0 / 3.0
          ret += (150.0 * Math.sin(x / 12.0 * PI) + 300.0 * Math.sin(x / 30.0 * PI)) * 2.0 / 3.0
          return ret
      },









  handleSingleData(id, data) {
    // console.log('handleSingleData',id,data)
    let obj = {
      id: id,
      path: [],
      filecolor: "#000",
      strokcolor: "#409EFF",
      weight: this.strokeWeight,
      strokeopacity: this.strokeOpacity,
      fillopacity: this.fillOpacity,
    };

    const pointobj = data.lnglat.split(";");
    const pointArr = pointobj.map((point) => {
      const lnglat = point.split(",");
       return { lng:parseFloat(lnglat[0]), lat:parseFloat(lnglat[1]) };
      // const newlnglat = this.BD09II2WGS84(parseFloat(lnglat[0]),parseFloat(lnglat[1]))
      // console.log('0000',newlnglat)
      // return { lng: parseFloat(newlnglat[0]), lat:parseFloat(newlnglat[1]) };
    });
    obj.path = pointArr;
    obj.filecolor = data.color;

    return obj;
  },



  getPolygondata() {
    //获取polygondata
    this.changeBounds();
  },

  handler({ BMap, map }) {
    // var view = map.getViewport(eval(this.markerdata))
    // this.zoom = view.zoom
    // this.centerpoint = view.center
  },

  //缩放结束事件
  syncCenterAndZoom(e) {
    //获取当前中心点
    const { lng, lat } = e.target.getCenter();

    //获取当前的zoom
    const currentZoom = e.target.getZoom();

    if (currentZoom >= 11) {
      //获取多边形的数据
      this.map = e.target;
      this.getPolygondata();
      this.showPloygon = true;
    } else {
      this.showPloygon = false;
    }

    this.currentzoom = currentZoom;
    // this.$emit('curzoom',currentZoom)
  },

  //处理百度地图数据
  handlerBaiduData(data) {
    const typearr = [
      this.type1,
      this.type1,
      this.type2,
      this.type3,
      this.type4,
    ];
    const colorarr = [this.bg1, this.bg1, this.bg2, this.bg3, this.bg4];

    const handlerdata = data.map((item) => {
      item.icon = typearr[item.usecate];
      item.content =
        item.locationnum ||
        item.noticenum ||
        item.locationname ||
        item.location;
      item.style = {
        color: "#000",
        borderColor: colorarr[item.usecate],
        background: colorarr[item.usecate],
        fontSize: "12px",
        borderRadius: "5px",
        paddingRight: "3px",
      };
      item.offset = { width: 25, height: 12 };
      item.checked = true;
      return item;
    });
    // console.log('handlerdata', handlerdata)

    if (data.length > 100 || data.length === 0) {
      this.less100 = false;
      // this.clustererdata = handlerdata

      this.markerdata = handlerdata;
    } else {
      this.less100 = true;

      this.markerdata = handlerdata;
    }

    this.handleListData(data);
  },

  //点击左侧列表跳转到右侧地图的点位置
  toMapPoint(data) {
    // alert(this.less100)
    if (this.less100) {
      var that = this;

      this.rightList = this.rightList.map((item) => {
        if (item.id === data.id) {
          const id = item.id;
          // alert(id)
          // const newMarkerdata = []
     

          this.markerdata = this.markerdata.map((marker) => {
            if (marker.id === id) {
              marker.checked = item.checked = !data.checked;

               if (!data.checked) {
                   marker.hide = false
                } else {
                   marker.hide = true
                }

             
            }

            return marker;
          });

          if (that.polygonPath.length > 0) {
            that.polygonPath.map((polygon) => {
              if (polygon.id === id) {
                if (!data.checked) {
                  polygon.hide = false;
                } else {
                  polygon.hide = true;
                }
              }

              return polygon;
            });
          }
        }
        return item;
      });
    } else {
      this.currentSelectItemID = data.id;
      this.markerclick({lat: data.lat_gaode, lng: data.lng_gaode});
    }
  },
  //处理右侧列表数据
  handleListData(data) {
    console.log("data---", data);
    const list = [];
    data.map((item, index) => {
      if (index < 100) {
        item.title =
          item.locationnum ||
          item.locationname ||
          item.noticenum ||
          item.location;
        item.checked = true;
        list.push(item);
      }
    });
    // console.log('list',list)
    this.rightList = list;
  },

  changeBounds() {
    const bounds = this.map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();
    this.where.ll_lng = sw.lng;
    this.where.ll_lat = sw.lat;
    this.where.ru_lng = ne.lng;
    this.where.ru_lat = ne.lat;
  }
  
  },
};
</script>

<style lang="scss"  scoped>
/* 隐藏国企以及版权标识 */
::v-deep .leaflet-control-attribution,
.leaflet-control {
  display: none !important;
}

.plugin{
  height:0px;
  width:120px;
  display: flex;
  background: #fff;
  position: relative;
  float: left;
  top:20px;
  z-index: 1000;
  left:60px;
  .pbtn{
    width:60px;
    height:20px;
    color:#000;
    font-weight: bold;
    line-height: 20px;
    background: #fff;
    border: solid 1px #FFF;
    cursor: pointer;
  }
  .activebtn{
    color:#1384cf;
    border: solid 1px #1384cf;
  }
}

.detailbox {
  display: flex;
  flex-direction: column;
  .tltlecon {
    font-size: 16px;
    font-weight: bold;
    margin: 10px;
  }
  .line {
    display: flex;
    flex-direction: row;
    line-height: 20px;

    .left {
      font-size: 12px;
      width: 30%;
      margin-left: 1%;
      height: 100%;
      overflow: hidden;
      background: #fff;
      padding-left: 5px;
      display: flex;
      flex-direction: column;
    }
    .right {
      font-size: 12px;
      width: 70%;
      height: 100%;
      overflow: hidden;
      background: #fff;
      padding-left: 5px;
      display: flex;
      flex-direction: column;
    }
  }
  .more {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 12px;
    color: #28a7e1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn {
      width: 200px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 12px;
      color: #fff;
      background: #28a7e1;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

.color1 {
  background: #f4ea2a;
}

.color2 {
  background-color: #e6b481;
}

.color3 {
  background-color: #ff0000;
}

.color4 {
  background-color: #e64fd6;
}

.title {
  font-size: 12px;
  width: auto;
}

.yuan {
  width: 80px;
  height: 80px;
  background: rgb(64, 192, 224);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  line-height: 80px;
  text-align: center;
  color: #fff;
  border: solid 1px rgba(250, 250, 244, 0.3);
}

.map {
  width: 100%;
  min-height: 500px;
  height: calc(100vh - 100px);
}

.rightmap {
  width: 15%;
  margin-left: 1%;
  height: 100%;
  overflow: hidden;
  background: #f0f3f8;
  padding-left: 5px;
  display: flex;
  flex-direction: column;

  // background:red;
  .listtitle {
    position: relative;
    top: 0;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
  }

  .listbox {
    // max-height: calc( 100vh - 220px );
    // height: calc( 100vh - 260px );
    // display: flex;
    flex: 1;
    overflow-y: scroll;
  }

  .list {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .listitem {
      padding-left: 10px;
      height: 24px;
      line-height: 24px;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      font-weight: 500;
      // background: red;
    }

    .current {
      color: #28a7e1;
      font-weight: bold;
    }
  }
}

.pageContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

.leftmap {
  width: 84%;
  // background: red;
}




</style>