<template>
	<div class="conContainer">
		<breadcrumb icon="el-icon-s-grid"></breadcrumb>

		<el-tabs class="modemenu" v-if="!fromMapPage" v-model="mode">
			<el-tab-pane label="地图模式" name="map">
				<span slot="label"><i :class="{ icon: true, dtms: true, dtmsChecked: mode === 'map' }"></i> 地图模式</span>
			</el-tab-pane>

			<el-tab-pane label="表单模式" name="table">
				<span slot="label"><i :class="{ icon: true, bdms: true, bdmsChecked: mode === 'table' }"></i>表单模式</span>
			</el-tab-pane>

			<el-tab-pane v-if="type === 3" label="成交分析" name="anylise">
				<span slot="label"><i :class="{ icon: true, cjfx: true, cjfxChecked: mode === 'anylise' }"></i>成交分析</span>
			</el-tab-pane>

		</el-tabs>

		<el-tabs class="modemenu" v-if="fromMapPage" v-model="activecate" @tab-click="cateClick">
			<el-tab-pane label="成交数据" name="chengjiao">
			</el-tab-pane>

			<el-tab-pane label="正在公告" name="gonggao">
			</el-tab-pane>

			<el-tab-pane label="拟上市" name="ni">
			</el-tab-pane>

		</el-tabs>


		<div v-if="mode !== 'anylise'" class="conditionBox">
			<el-form :inline="true" :model="condition" class="demo-form-inline">

				<el-form-item label="省/市/区县:">

					<el-cascader style="width:270px;" :checkStrictly="true" :emitPath="false" v-model="condition.city_plate_id"
						size="mini" :options="citylist" :props="cascaderProp" collapse-tags clearable>
					</el-cascader>

				</el-form-item>

				<el-form-item label="用途:">
					<el-checkbox-group v-model="condition.usecate">
						<el-checkbox v-for="item in usecate" :key="item.id" :label="item.id">{{ item.name }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>

				<el-form-item v-if="type === 2" label="限时竞价日期:">

					<el-date-picker value-format="yyyy-MM-dd" v-model="condition.biddingst" style="width:230px;" size="small"
						type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" :picker-options="pickerOptions">
					</el-date-picker>

				</el-form-item>


				<el-form-item v-if="type === 3" label="成交日期:">

					<el-date-picker value-format="yyyy-MM-dd" v-model="condition.tradedate" style="width:230px;" size="small"
						type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" :picker-options="pickerOptions">
					</el-date-picker>

				</el-form-item>


				<el-form-item v-if="(type === 3 && !hideafford) || type === 2" label="含纯保障">
					<el-switch v-model="condition.affordtype" active-color="#28A7E1" inactive-color="#ddd">
					</el-switch>
				</el-form-item>


				<el-form-item>
					<el-input v-model="condition.search" placeholder="请输入 公告号/宗地编号/地块名称/地块位置/竞得单位/楼盘" style="width:400px"
						size="small" clearable>
					</el-input>
				</el-form-item>

				<el-form-item>
					<el-button size="mini" icon="el-icon-search" @click="submitSearch" type="primary">查询</el-button>
				</el-form-item>

			</el-form>
		</div>

		<div v-if="mode === 'map'" class="map">
			<!-- mapmod -->
			<!-- <mapmode :center="mapcenter" :searchdata="searchData" :singledata="singledata" :areaCountData="areaCountData"
				:mapdata="mapdata" @bounds="getBounds"></mapmode> -->
			<leafletmapmode  :center="mapcenter" :searchdata="searchData" :singledata="singledata" :areaCountData="areaCountData"
				:mapdata="mapdata"   @bounds="getBounds"></leafletmapmode>
			<!--  写一个遮罩层-->
			<CustomDialog :visible.sync="showPop"></CustomDialog>
		</div>
		<div v-if="mode === 'anylise'" class="anylise">
			<anylise></anylise>
			<!--  写一个遮罩层-->
			<CustomDialog :visible.sync="showPop"></CustomDialog>
		</div>
		<div v-if="mode === 'table'" class="table">

			<div class="selectbtn">
				<!-- defaultdata显示的全部数据   checked选中的数据  -->
				<div class="btnleft">
					<select-form :alldata="allFileddata" @complate="complateSelect" @changedata="currentdata"></select-form>
					<div class="totaltag" v-if="type === 3">
						<el-tag size="medium">地块总宗数：{{ total }}</el-tag>
						<el-tag size="medium">地块总面积(㎡)：{{ totaldata.acreage }}</el-tag>
						<el-tag size="medium">建筑总面积(㎡)：{{ totaldata.buildacreage }}</el-tag>
						<el-tag size="medium">成交总价(万元)：{{ totaldata.totalprice }}</el-tag>
						<el-tag size="medium">平均楼面价(元/㎡)：{{ totaldata.floorprice }}</el-tag>
						<el-tag size="medium">总溢价：{{ totaldata.overflow }}</el-tag>
					</div>
				</div>


				<download-button type="excel" :params="{
			goods_id: 5,
			condition: downloadCondition
		}" text="导出Excel"></download-button>

			</div>

			<!-- table-data -->
			<table-data :data="table" @tolocation="tolocation" @sortchange="sortchange">
		
			</table-data>
			
			<el-pagination style="margin:30px 0" background @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="currentpage" :page-sizes="pagesizes" :page-size="pagesize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
			
			<!--  写一个遮罩层-->
			<CustomDialog :visible.sync="showPop"></CustomDialog>

		

		</div>

	</div>
</template>
<script>
import { userShow } from '@/api/index'
import CustomDialog from '@/components/Dialog.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import TableData from '@/components/common/TableData.vue'
import SelectForm from '@/components/common/SelectForm.vue'
import Anylise from '@/components/data/anylise.vue'
import Mapmode from '@/components/common/Mapmode.vue'

import leafletmapmode from '@/components/leaflet/leafletmapmode.vue'

import { deepCopy } from '@/utils/deepclone.js'


import {leafletmapurl,isgaode} from '@/settings.js'

import DownloadButton from "@/components/down/DownloadButton.vue"
import { getCityLnglat, nextLevelCity, useYearList, useCateList, FiledData, table, downloadExcel, citylist, downloadZip, mapData, mapCount, getDetail } from '@/api/data.js'

export default {
	props: {
		type: Number,//1 拟上市  2 正在公告  3//成交
		required: true
	},
	emit: ['setType'],
	data() {
		return {
			is_gaode:isgaode,
			showPop: false,
			areaCountData: [

			],
			activecate: 'chengjiao', //地图进入的当前现实分类，默认是成交  
			orderrule: '',
			ordercolumn: '',
			downloadCondition: {},
			singledata: {}, //地图中心点
			cityname: '', //当前城市
			cname: '',  //点击查询后当前城市传入地图组建的字段
			pickerOptions: {
				shortcuts: [{
					text: '近一月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '近三月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				},
				{
					text: '近六月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
						picker.$emit('pick', [start, end]);
					}
				},
				{
					text: '近一年',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			cascaderProp: {
				multiple: true,
				emitPath: false
				// checkStrictly:true
			},
			citylist: [],
			// mode: 'anylise',//选中的模式
			// mode: 'map',//选中的模式
			// mode: 'table',//选中的模式  map 地图模式  table 表格模式



			headerData: [], //表头数据
			arealist: [],
			usecate: [],
			yearlist: [],
			allFileddata: [],
			table: {},

			pagesize: 30, //默认分页条数
			pagesizes: [30, 50, 100, 200], //分页条数选择
			currentpage: 1, //当前页默认1
			total: 0, //总条数
			hideafford: true,

			totaldata: {
				acreage: 0,
				buildacreage: 0,
				totalprice: 0,
				floorprice: 0,
				overflow: '0%'
			},
			where: {}, //接受地图组建经纬度条件，
			changeCenter: 0,//0-1之间的随机数用于触发地图中心点改变 从而请求数据
			mapdata: [],//地图请求的数据  传入地图组建
			mapcenter: {
				lng: 0,
				lat: 0
			},// 地图中心点
			showmap: false,
			polygondata: [], //多边形数据
			searchData: [],//搜索数据
			fromMapPage: false,
			currentDataParams: {},
			currentCountParams: {},

			// condition: {
			// 	city_plate_id: [14, 16, 18, 73, 74],
			// 	usecate: [],
			// 	search: '',
			// 	tradedate: [],
			// 	affordtype:false
			// },

		}
	},

	computed: {

		currentzoom: {
			get() {
				return this.$store.state.currentzoom
			},
			set(v) {
				this.$store.commit('currentzoom', v)
			}
		},
		condition: {
			get() {
				return this.$store.state.condition
			}

		},

		mode: {
			get() {
				return this.$store.state.mapmode
			},
			set(v) {
				this.$store.commit('mapmode', v)
			}

		}



	},


	mounted() {

		this.$nextTick(() => {
			document.onselectstart = new Function("event.returnValue=false");
		})

		if (this.$route.query.mode) {
			this.mode = this.$route.query.mode
		}

		Promise.all(
			[
				//初始化城市区域列表 
				this.getBigdataCity(),

				// this.getCurrentAreaByID(this.shi),
				//初始化用途
				this.getUseCate(),
				//初始化年限
				// this.getUseYear(),
				//初始化表头选择数据
				this.getFiledData()
			]
		).then(res => {
			this.getData()
			this.getusershow()

		})

	},
	watch: {



		$route: {
			handler(to, from) {
				// console.log('to',to,'from',from)
				if (to.fullPath === '/data/map') {
					this.mode = 'map'
					this.fromMapPage = true
				} else {
					this.fromMapPage = false
				}
			},
			immediate: true,
			deep: true

		},



		condition: {
			handler(newvalue) {
				// alert(111)
				this.$store.commit('condition', newvalue);
			},
			deep: true
			// immediate: true
		},
		//监听城市选择数据变化 获取当前的城市 用于地图初始化中心点
		'condition.city_plate_id': {
			handler(newvalue) {
				const areaid = newvalue[0];
				const cname = this.getCityidBychild(areaid)
				this.cityname = cname
			},
			deep: true,
			immediate: true
		},
		//监听表单切换
		mode: {
			handler(nv) {
				this.getData()
				this.getusershow()
			},
			deep: true
		},
		//监听页面切换
		type: {
			handler(nv) {
				if (nv !== 3 && this.mode === 'anylise') { //是成交 并且 显示成交分析  更改mode为表单模式
					this.mode = 'table'
				}
				this.getData()				
				//查询当前用户是否是显示内容
				this.getusershow()
				
			}
		},

		//初始化获取当前表头字段
		allFileddata: {
			immediate: true,
			handler(newValue, oldValue) {
				let header = []
				newValue.filter(item => {
					if (item.checked) {
						header.push(item.field)
					}
				})
				this.headerData = header
			}

		},
		'condition.tradedate': {
			handler(v) {
				const start = Number(v[0].slice(0, 4))
				if (start < 2022) {
					this.hideafford = true
				} else {
					this.hideafford = false
				}
			},
			deep: true,
			immediate: true
		},

		currentzoom: {

			handler(v) {
				this.getMapData()

			},
			deep: true,
			immediate: true
		}

	},

	components: {
		leafletmapmode,
		Breadcrumb,
		TableData,
		SelectForm,
		Mapmode,
		Anylise,
		DownloadButton,
		CustomDialog
	},
	methods: {

	

		singlechangecenter(v){
			console.log('----',v)
			this.mapcenter = v
		},


		getusershow() {
			if(this.type === 3){
				userShow().then(res => {
				if (res.data.is_show) {
					this.showPop = true
				} else {
					this.showPop = false
				}

			})
			}else{
				this.showPop = false
			}
			
		},
		// MapAreaCountData(params) {

		// 	// this.areaCountData = [
		// 	// 	{
		// 	// 		name: '姑苏区',
		// 	// 		lng: 120.59919906516177,
		// 	// 		lat: 31.36219267339592,
		// 	// 		count: 50
		// 	// 	},
		// 	// 	{
		// 	// 		name: '高薪区',
		// 	// 		lng: 120.5124685188687,
		// 	// 		lat: 31.3846354750302,
		// 	// 		count: 30
		// 	// 	}
		// 	// ]
		// },

		cateClick(tab, event) {
			// this.mapdata = []
			let type
			switch (this.activecate) {
				case 'chengjiao':
					type = 3;
					break;
				case 'gonggao':
					type = 2;
					break;
				case 'ni':
					type = 1;
					break;
				default:
					type = 3
			}

			this.$emit('setType', type)

		},

		sortchange(e) {
			this.ordercolumn = e.prop
			if (e.order === "descending") {
				this.orderrule = 'desc'
			}
			if (e.order === "ascending") {
				this.orderrule = 'asc'
			}
			this.getTable()
		},

		//表格位置点击事件
		tolocation(id) {
			this.mode = 'map'
			var _this = this
			setTimeout(function () {
				getDetail({ id: id }).then(res => {

					const data = res.data
					if (data != null) {
						_this.searchData = [data]

					}

				})
			}, 600)

		},
		//获取地图的四点条件	
		getBounds(bounds) {
			this.where = bounds
			this.getMapData()
		},

		//获取2级的城市id
		getCityidBychild(id) {
			let res = ''
			// console.log('citylist', this.citylist)
			this.citylist.map(level1 => {
				if (level1.children) {

					level1.children.map(level2 => {
						const name = level2.label
						if (level2.children) {
							level2.children.some(level3 => {

								if (level3.value === id) {
									res = name
									return true
								}
								if (level3.children) {
									level3.children.some(level4 => {
										if (level4.value === id) {
											res = name
											return true
										}
									})
								}


							})
						}
					})
				}
			})
			return res;
		},
		//表头选择完成确定触发的事件
		complateSelect(checkedList) {
			// console.log('确定',checkedList)
			this.getTable()

		},


		handleSizeChange(size) {
			this.pagesize = size
			this.getTable()
		},
		handleCurrentChange(page) {
			this.currentpage = page
			this.getTable()
		},



		getPolygonData() {



			//获取地图数据
			mapData(params).then(res => {
				this.polygondata = res.data
			})

		},


		//获取地图模式数据
		getMapData() {

			let params = {
				
			}
			if (this.type === 3) {
				params = {
					is_gaode:this.is_gaode,
					type: this.type,
					city_plate_id: JSON.stringify(this.condition.city_plate_id),
					usecate: JSON.stringify(this.condition.usecate),
					// search: this.condition.search,

					tradedate: JSON.stringify(this.condition.tradedate),
					...this.where  //获取经纬度
				}

			} else {
				params = {
					is_gaode:this.is_gaode,
					type: this.type,
					city_plate_id: JSON.stringify(this.condition.city_plate_id),
					usecate: JSON.stringify(this.condition.usecate),
					// search: this.condition.search,
					...this.where  //获取经纬度
				}
			}
			if (this.type === 2) {
			  params.biddingst =  JSON.stringify(this.condition.biddingst) 
			}

			if (this.type === 2 || this.type === 3) {
				params.affordtype = this.condition.affordtype
			}


			//获取地图数据


			if (this.currentzoom >= 11) {



				if (JSON.stringify(this.where) === '{}' || this.where.ll_lat == null || this.where.ll_lat <= 0) {
					return false
				}
				if (this.currentDataParams === params) {
					return false
				}
				this.areaCountData = []
				mapData(params).then(res => {
					this.currentDataParams = params
					this.mapdata = res.data
					if (this.condition.search) {
						this.getSearchData()
					}
				})

			} else {
				if (this.currentCountParams === params) {
					return false
				}
				this.mapdata = []

				mapCount(params).then(res => {
					this.currentCountParams = params
					this.areaCountData = res.data
				})

			}


		},

		getSearchData() {

			let params = {}
			if (this.type === 3) {
				params = {
					type: this.type,
					is_gaode:this.is_gaode,
					city_plate_id: JSON.stringify(this.condition.city_plate_id),
					usecate: JSON.stringify(this.condition.usecate),
					search: this.condition.search,

					tradedate: JSON.stringify(this.condition.tradedate),

				}

			} else {
				params = {
					type: this.type,
					is_gaode:this.is_gaode,
					city_plate_id: JSON.stringify(this.condition.city_plate_id),
					usecate: JSON.stringify(this.condition.usecate),
					search: this.condition.search

				}
			}
			if (this.type === 2) {
			  params.biddingst =  JSON.stringify(this.condition.biddingst) 
			}

			if (this.type === 2 || this.type === 3) {
				params.affordtype = this.condition.affordtype
			}



			// console.log('params', params)
			//获取地图数据
			mapData(params).then(res => {
				// console.log('====',res.data)
				if (JSON.stringify(res.data) === '[]') {
					// this.$message.info('没有找到相关数据信息')
				} else {
					this.searchData = res.data

				}

			})
		},

		submitSearch() {
			this.currentpage = 1;
			this.getData()
		},

		//获取查询的数据 根据地图模式和表单模式不同，获取不同的数据
		getData() {
			if (this.mode === 'map') {
				this.mapdata = []
				this.initMapData()
			}
			if (this.mode === 'table') {
				this.getTable()
			}

		},

		//地图模式下点击查询按钮
		async initMapData() {
			const firstCityID = this.condition.city_plate_id[0]
			//获取中心点
			const mapcenter = await this.getCityCenter(firstCityID)
			this.mapcenter = mapcenter
			this.getMapData()
			// this.getPolygonData()

		},

		getCityCenter(id) {
			return new Promise(function (resolve, reject) {
				getCityLnglat({ dcity_id: id }).then(res => {
					// resolve([res.data.lng, res.data.lat])
					resolve({ lng: res.data.lng, lat: res.data.lat })
				})
			})
		},


		//获取表数据
		getTable() {
			let params = {}
			let paramsdown = {}
			if (this.type === 3) {
				params = {
					type: this.type,
					city_plate_id: JSON.stringify(this.condition.city_plate_id),
					usecate: JSON.stringify(this.condition.usecate),
					tradedate: JSON.stringify(this.condition.tradedate),
					tableHeader: JSON.stringify(this.headerData),
					search: JSON.stringify(this.condition.search),
					size: this.pagesize,
					page: this.currentpage,
					order_column: this.ordercolumn,
					order_rule: this.orderrule
				}

			} else {
				params = {
					type: this.type,
					city_plate_id: JSON.stringify(this.condition.city_plate_id),
					usecate: JSON.stringify(this.condition.usecate),
					year: this.condition.year,
					tableHeader: JSON.stringify(this.headerData),
					search: JSON.stringify(this.condition.search),
					size: this.pagesize,
					page: this.currentpage,
					order_column: this.ordercolumn,
					order_rule: this.orderrule

				}
			}

			if (this.type === 2) {
			  params.biddingst =  JSON.stringify(this.condition.biddingst) 
			}

			if (this.type === 2 || this.type === 3) {
				params.affordtype = this.condition.affordtype
			}


			if (this.type === 3) {
				paramsdown = {
					type: this.type,
					city_plate_id: this.condition.city_plate_id,
					usecate: this.condition.usecate,
					tradedate: this.condition.tradedate,
					tableHeader: this.headerData,
					search: JSON.stringify(this.condition.search),
					size: this.pagesize,
					page: this.currentpage,
					order_column: this.ordercolumn,
					order_rule: this.orderrule

				}

			} else {
				paramsdown = {
					type: this.type,
					city_plate_id: this.condition.city_plate_id,
					usecate: this.condition.usecate,
					year: this.condition.year,
					tableHeader: this.headerData,
					search: JSON.stringify(this.condition.search),
					size: this.pagesize,
					page: this.currentpage,
					order_column: this.ordercolumn,
					order_rule: this.orderrule

				}
			}

			
			if (this.type === 2) {
			  paramsdown.biddingst =  this.condition.biddingst
			}

			if (this.type === 2 || this.type === 3) {
				paramsdown.affordtype = this.condition.affordtype
			}


			const condition = deepCopy(paramsdown)
			delete condition.size
			delete condition.page

			this.downloadCondition = condition

			const headerDataWidth = [
				{
					name: 'location',
					width: '300'
				},
				{
					name: 'id',
					width: '60'
				},
				{
					name: 'sname',
					width: '70'
				},
				{
					name: 'cname',
					width: '70'
				},
				{
					name: 'zname',
					width: '80'
				},
				{
					name: 'qname',
					width: '80'
				},
				{
					name: 'company',
					width: '260'
				},

				{
					name: 'usecatename',
					width: '80'
				},
				{
					name: 'usedetail',
					width: '180'
				},
				{
					name: 'useyears',
					width: '80'
				},
				{
					name: 'buildacreage',
					width: '150'
				},
				{
					name: 'volume',
					width: '150'
				},
				{
					name: 'overrage',
					width: '90'
				},
				{
					name: 'acreagemu',
					width: '140'
				},
				{
					name: 'acreage',
					width: '140'
				},
				{
					name: 'tradedate',
					width: '100'
				},
				{
					name: 'tradeprice',
					width: '140'
				},
				{
					name: 'floorprice',
					width: '130'
				},
				{
					name: 'locationnum',
					width: '180'
				},
				{
					name: 'noticenum',
					width: '180'
				}
			]

			const headerSort = [
				{
					name: 'tradedate',
					sortable: 'custom'
				},
				{
					name: 'acreage',
					sortable: 'custom'
				},
				{
					name: 'acreagemu',
					sortable: 'custom'
				},
				{
					name: 'buildacreage',
					sortable: 'custom'
				},
				{
					name: 'totalprice',
					sortable: 'custom'
				},
				{
					name: 'floorprice',
					sortable: 'custom'
				},
				{
					name: 'tradeprice',
					sortable: 'custom'
				},
				{
					name: 'overrage',
					sortable: 'custom'
				},
			]


			if (params.usecate !== '[]' && params.year !== '[]' && params.tableHeader !== '[]') {

				table(params).then(res => {
					const headerlist = res.data.list.headerData
					console.log('======', headerlist)

					headerlist.map(item => {
						let width = '150'

						headerDataWidth.map(i => {
							if (item.field === i.name) {
								width = i.width
							}

						})
						headerSort.map(j => {
							if (item.field === j.name) {
								item.sortable = 'custom'
							}
						})

						item.width = width
					})



					res.data.list.hearderData = headerlist
					this.table = res.data.list
					// console.log('table=====',table)

					this.total = res.data.total
					if (this.type === 3) {
						this.totaldata = res.data.list.totaldata
					}
				})
			}

		},


		//导出excel
		exportExcel() {
			let params = {}
			if (this.type === 3) {
				params = {
					type: this.type,
					city_plate_id: JSON.stringify(this.condition.city_plate_id),
					usecate: JSON.stringify(this.condition.usecate),
					tradedate: JSON.stringify(this.condition.tradedate),
					tableHeader: JSON.stringify(this.headerData)


				}

			} else {
				params = {
					type: this.type,
					city_plate_id: JSON.stringify(this.condition.city_plate_id),
					usecate: JSON.stringify(this.condition.usecate),
					// year: this.condition.year,
					tableHeader: JSON.stringify(this.headerData)
				}
			}

			
			if (this.type === 2) {
			  params.biddingst =  JSON.stringify(this.condition.biddingst) 
			}

			downloadExcel(params).then(res => {
				window.location.href = res.data.url
			})
		},

		//获取大数据city
		getBigdataCity() {
			var that = this
			return new Promise(function (resolve, reject) {
				citylist().then(res => {
					that.citylist = res.data
					return resolve(res.data)
				})
			})
		},


		//获取用途
		getUseCate() {
			var that = this
			return new Promise(function (resolve, reject) {
				useCateList().then(res => {
					// alert(that.condition.usecate)
					// if(that.condition.usecate === ''){

					// that.condition.usecate = [res.data[0].id, res.data[1].id, res.data[2].id, res.data[3].id]  //获取第一个为默认
					// }

					that.usecate = res.data
					return resolve(res.data)
				})
			})
		},

		//获取年
		// getUseYear() {
		// 	var that = this
		// 	return new Promise(function (resolve, reject) {
		// 		useYearList().then(res => {
		// 			that.condition.year = res.data[0].id  //获取第一个为默认
		// 			that.yearlist = res.data
		// 			return resolve(res.data)
		// 		})
		// 	})
		// },

		//获取字段数据
		getFiledData() {
			var that = this
			const params = { type: that.type }
			return new Promise(function (resolve, reject) {
				FiledData(params).then(res => {
					that.allFileddata = res.data
					return resolve(res.data)
				})

			})

		},

		//获取下级城市列表 
		getCurrentAreaByID(id) {
			var that = this
			return new Promise(function (resolve, reject) {
				nextLevelCity({ city_plate_id: id }).then(res => {
					//初始化条件为第一个
					that.condition.city_plate_id = that.condition.city_plate_id === 0 ? res.data[0].city_plate_id : that.condition.city_plate_id

					that.arealist = res.data
					resolve(res.data)
				})
			})
		},

		currentdata(v) {
			this.headerData = v
		}
	}

}



</script>
<style lang="scss" scoped>
.icon {
	height: 16px;
	width: 16px;
	display: inline-block;
	position: relative;
	top: 3px;
	margin-right: 2px;
}

.dtms {
	background: url('/pic/dtms1.png') no-repeat center center;
	background-size: cover;
}

.dtmsChecked {
	background: url('/pic/dtms2.png') no-repeat center center;
	background-size: cover;

}

.bdms {
	background: url('/pic/bdms1.png') no-repeat center center;
	background-size: cover;
}

.bdmsChecked {
	background: url('/pic/bdms2.png') no-repeat center center;
	background-size: cover;
}

.cjfx {
	background: url('/pic/cjfx1.png') no-repeat center center;
	background-size: cover;
}

.cjfxChecked {
	background: url('/pic/cjfx2.png') no-repeat center center;
	background-size: cover;
}

.conContainer {
	flex: 1;
	overflow-x: hidden;
	// height: auto;
	.modemenu {
		margin: 0 1%;
		position: relative;
		z-index: 11;
		background: #fff;
	}



	.conditionBox {
		position: relative;
		z-index: 11;
		width: 98%;
		margin-left: 1%;
		min-height: 54px;
		max-height: 100px;
		background: #F0F3F8;
		border-radius: 4px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 10px;

		.demo-form-inline {
			display: flex;
			flex-wrap: wrap;
			padding: 10px;
		}

		.el-checkbox {
			margin-right: 10px;
		}

		.el-form-item {
			margin: 0;
			margin-left: 10px;
		}

		.el-form--inline .el-form-item__label {
			font-weight: 600;
			font-size: 16px;
		}

	}


	.anylise {
		width: 98%;
		margin: 1%;
		// background:blue;
		overflow: hidden;
	}

	.map {
		width: 98%;
		margin: 1%;
		overflow: hidden;
		height: calc(100vh - 270px);
	}

	.table {

		width: 98%;
		margin: 1%;
		margin-top: 10px;
		overflow: scroll;

		.selectbtn {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.btnleft {
				display: flex;

				.totaltag {
					margin-left: 20px;

					.el-tag {
						margin-left: 5px;
					}
				}
			}

		}
	}

}


::v-deep .el-tabs__item:hover {
	color: #333;
}

::v-deep .el-tabs__item.is-active:hover {
	color: #28A7E1;
}
</style>