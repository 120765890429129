<template>
	<div class="pageContainer">
		<div class="leftmap" id="mapcon">

			<baidu-map class="map" :center="centerpoint" @moveend="syncCenterAndZoom" @zoomend="syncCenterAndZoom" :min-zoom="11"
				:zoom="currentzoom"  @ready="handler" :scroll-wheel-zoom="true">
				<bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
				<bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-navigation>
				<bm-scale anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-scale>


				 <div v-for="(item,index) in areaCountData" :key="item.id"  >

					<my-overlay
						:position="{lng: item.lng, lat: item.lat}"
						:text="item.count"
						:data="item"
						@setZoom="setzoom"
					>
					</my-overlay>

				</div> 



				 <div v-for="marker of markerdata" 	v-if="marker.checked"  :key="marker.id">
				
					<bm-marker  @click="markerclick(marker)"
						:icon="{ url: marker.icon, size: { width: 40, height: 50 }, opts: { imageSize: { width: 40, height: 50 } } }"
						:position="{ lng: marker.lng, lat: marker.lat }">
						<bm-label :offset="marker.offset" 	v-if="marker.checked"  @click="markerclick(marker)" :content="marker.content" :labelStyle="marker.style" title="Hover me" /> 
					</bm-marker>

					 
				</div> 

			 <div v-for="polygon of polygonPath" >
					<bm-polygon  :path="polygon.path" v-if="polygon.fillopacity" :fill-color="polygon.filecolor"
					:stroke-color="polygon.strokecolor" :stroke-opacity="polygon.strokeopacity" :stroke-weight="polygon.weight" />
				</div> 
			
				<!-- 这个包裹的div是为了解决切换展示数量和地块详细信息的 bug -->
				<div v-if="infoWindow.show"> 
					<bm-info-window  :position="infopoint" :title="infoWindow.title" :autoPan="true" :show="infoWindow.show"
						@close="infoWindowClose" @open="infoWindowOpen">
						<p v-html="infoWindow.contents" @click="triggerClick"></p>
					</bm-info-window>
				</div>

			</baidu-map>
		</div>
		<div class="rightmap">
			<div class="listtitle">宗地列表</div>
			<div class="listbox">
				<div class="list">
					<!-- <el-checkbox v-model="checked">备选项</el-checkbox> -->

					<div v-if="less100"><el-checkbox checked :class="{ listitem: true, current: item.id === currentSelectItemID }"
							v-for="item in rightList" @change="toMapPoint(item)" :key="item.id">{{ item.title }}</el-checkbox>
					</div>
					<div v-else v-for="item in rightList" :key="item.id"
						:class="{ listitem: true, current: item.id === currentSelectItemID }" @click="toMapPoint(item)">{{item.title}}</div>
				</div>



			</div>

		</div>

	

	</div>
</template>
<script>


import { BmlMarkerClusterer } from 'vue-baidu-map'

import MyOverlay from './MyOverlay.vue'

export default {
	props: {
		center: {
			type: Object,
			default:function(){
				return {
					lng:0,
					lat:0
				}
			}
		},
		mapdata: {
			type: Array,
			default: function () {
				return []
			}
		},
		singledata: {
			type: Object,
			default: function () {
				return {}
			}
		},
		areaCountData: {
			type: Array,
			default: function () {
				return []
			}
		},
		polygondata: {
			type: Array,
			default: function () {
				return []
			}
		},
		//搜索数据
		searchdata: {
			type: Array,
			default: function () {
				return []
			}
		}
	},


	data() {

		return {
			active:true,
			styles: [
				// url, size, opt_anchor, textColor, opt_textSize
				{

					textColor: '#f00',
					size: 20,
					opt_anchor: '#f00'
				}
			],
			infopoint: {},
			polygonPath: [],
			infoWindowData: {},//当前点击的info信息数据
			infoWindow: {
				show: false,
				contents: '',
				title:'',
			
			},



			type1: require('@/assets/type1.png'),
			type2: require('@/assets/type2.png'),
			type3: require('@/assets/type3.png'),
			type4: require('@/assets/type4.png'),

			bg1: '#f4ea2a',
			bg2: '#E6B481',
			bg3: '#ff0000',
			bg4: '#E64FD6',
			strokeWeight: 2, // 边框宽
			strokeOpacity: 1, // 线透明度
			fillOpacity: 0.6, // 透明度
			markers: [],
			visible: true,
			icon: './assets/type1.png',

			map: {},
			BMap: {},
			geodata: "",

			centerpoint: { lat: 0, lng: 0 },
			count: 1,
			// zoom: 12,
			polygonsIDArr: [], //已经生成的多变形的id数组
			markersIDArr: [], //已经生成的点的id数组
			//本次的markers
			currentMarkersIDArr: [],
			currentPolygonsIDArr: [],
			//本次新增的id
			lastCurrentMarkersAdd: [],
			lastCurrentPolygonsAdd: [],
			//记录上一次的markers数组
			lastMarkersIDArr: [],
			lastPolygonsIDArr: [],
			rightList: [],
			currentSelectItemID: {},//当前点击的项目ID
			showClusterer: true, //是否显示聚合
			clustererdata: [],
		
			markerdata: [],
			showPloygon: false,
			where: {
				// currentZoom: 8,
				// dateValue: [new Date('2021-01-01').format('yyyy-MM-dd'), new Date().format('yyyy-MM-dd')], // 默认日期
				ll_lng: '', // 经度 左下角
				ll_lat: '', // 纬度 左下角
				ru_lng: '', // 经度 右上角
				ru_lat: '' // 纬度 右上角
			},
			less100: false,
			searchinfodata:null,
		};
	},

	computed:{
		

		currentzoom:{
			get(){
				return this.$store.state.currentzoom
			},
			set(v){
				this.$store.commit('currentzoom',v)
			}	
		}
	},
	components: {
		BmlMarkerClusterer,
		MyOverlay
	},

	mounted() {
		
	},

	watch: {
		searchdata: {
			handler(data, ov) {


				if (data.length === 1) { //搜索的
					this.centerpoint = { lng: data[0].lng, lat: data[0].lat }
					this.markerclick(data[0])

				}

			},
			deep: true
		},
		// polygondata: {
		// 	handler(data, ov) {
		// 		// alert('polygondata')

			
		// 		// this.handlerBaiduData(this.mapdata)
		// 	},

		// 	deep: true,
		// 	immediate: true
		// },
		center: {
			handler(nv,ov) {
				console.log('--nv--',nv,ov)
				console.log(this.centerpoint)
				if(nv.lng!==0 && nv.lat!==0){
				
					this.centerpoint = {
						lng : nv.lng,
						lat : nv.lat
					}
				}
				
			}
		},


		singledata: {
			handler(data, ov) {
				this.singleData(data)
			},
			deep: true
		},

		mapdata: {
			handler(nv, ov) {
				this.handerPolygondata(nv)

				this.handlerBaiduData(nv)

			},
			deep: true
		},
		where: {
			handler(nv, ov) {
				this.$emit('bounds', nv)
			},
			deep: true,
			immediate: true
		},




	},


	methods: {
		setzoom(data){

			this.centerpoint = {
				lng:data.lng,
				lat:data.lat
			}

			this.currentzoom = 13

		},
	

		triggerClick(event) {
			if (event.target.id === 'more') {
				const id = this.infoWindowData.id
				this.$router.push('/data/zhibiao/' + id)
			}

		},

		//marker点击事件
		markerclick(e) {

			console.log('markerclick',e)
			
			this.infoWindowData = e
			this.infopoint = { lng: e.lng, lat: e.lat }
		
				this.infoWindow.show = true
				this.infoWindow.contents = this.createInfoContent(e)
				this.infoWindow.title = '地块信息'
			
		
			

		},

		createInfoContent(itemdata) {
			// 创建图文信息窗口
			let sContent = `
			<div  style="display:flex;flex-direction: column;" >
				<div   style="font-weight: 500;
color: #000000;line-height: 24px;text-shadow: 0px 5px 26px rgba(0,0,0,0.2000);display:flex;flex-direction: row;justify-content:flex-start;align-items:center;" >
					<div  style="width:90px;text-align:right;"> 省/市/区县</div>
					<div  style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.ssq}</div>
				</div>`

			if (itemdata.noticenum) {
				sContent += `	<div style="font-weight: 500;
color: #000000;line-height: 24px;text-shadow: 0px 5px 26px rgba(0,0,0,0.2000);display:flex;flex-direction: row;justify-content:flex-start;align-items:center;"  style="display:flex;flex-direction: row;justify-content:space-between;align-items:center;" >
					<div style="width:90px;text-align:right;"> 公告号</div>
					<div style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.noticenum}</div>
				</div>`
			}

			if (itemdata.locationnum) {
				sContent += `<div style="font-weight: 500;
color: #000000;line-height: 24px;text-shadow: 0px 5px 26px rgba(0,0,0,0.2000);display:flex;flex-direction: row;justify-content:flex-start;align-items:center;"  style="display:flex;flex-direction: row;justify-content:space-between;align-items:center;" >
					<div style="width:90px;text-align:right;"> 宗地编号</div>
					<div style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.locationnum}</div>
				</div>`
			}

			if (itemdata.locationname) {
				sContent += `<div style = "font-weight: 500;
				color: #000000; line - height: 24px; text - shadow: 0px 5px 26px rgba(0, 0, 0, 0.2000); display: flex; flex - direction: row; justify - content: flex - start; align - items: center; ">
					<div style = "width:90px;text-align:right;"> 地块名称</div >
						<div style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.locationname}</div>
				</div >`
			}

			if (itemdata.usecate) {
				sContent += `<div style="font-weight: 500;
color: #000000;line-height: 24px;text-shadow: 0px 5px 26px rgba(0,0,0,0.2000);display:flex;flex-direction: row;justify-content:flex-start;align-items:center;">
					<div style="width:90px;text-align:right;"> 用途</div>
					<div style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.usecatename}</div>
				</div>`
			}

			if (itemdata.acreage) {
				sContent += `
				<div style="font-weight: 500;
color: #000000;line-height: 24px;text-shadow: 0px 5px 26px rgba(0,0,0,0.2000);display:flex;flex-direction: row;justify-content:flex-start;align-items:center;">
					<div style="width:90px;text-align:right;"> 面积(m²)</div>
					<div style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.acreage}</div>
				</div>`
			}

			if (itemdata.buildacreage) {
				sContent += `
				<div style="font-weight: 500;
color: #000000;line-height: 24px;text-shadow: 0px 5px 26px rgba(0,0,0,0.2000);display:flex;flex-direction: row;justify-content:flex-start;align-items:center;">
					<div style="width:90px;text-align:right;"> 建筑面积(m²)</div>
					<div style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.buildacreage}</div>
				</div>`
			}

			if (itemdata.volume) {
				sContent += `
				<div style="font-weight: 500;
color: #000000;line-height: 24px;text-shadow: 0px 5px 26px rgba(0,0,0,0.2000);display:flex;flex-direction: row;justify-content:flex-start;align-items:center;">
					<div style="width:90px;text-align:right;"> 容积率</div>
					<div style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.volume}</div>
				</div>
			</div >`
			}

			if (itemdata.company) {
				sContent += `
				<div style="font-weight: 500;
color: #000000;line-height: 24px;text-shadow: 0px 5px 26px rgba(0,0,0,0.2000);display:flex;flex-direction: row;justify-content:flex-start;align-items:center;">
					<div style="width:90px;text-align:right;"> 竞得单位</div>
					<div style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.company}</div>
				</div>
			</div >`
			}

			if (itemdata.totalsp) {
				sContent += `
				<div style="font-weight: 500;
color: #000000;line-height: 24px;text-shadow: 0px 5px 26px rgba(0,0,0,0.2000);display:flex;flex-direction: row;justify-content:flex-start;align-items:center;">
					<div style="width:90px;text-align:right;"> 挂牌价格</div>
					<div style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.totalsp}</div>
				</div>
			</div >`
			}

			if (itemdata.tradeprice) {
				sContent += `
				<div style="font-weight: 500;
color: #000000;line-height: 24px;text-shadow: 0px 5px 26px rgba(0,0,0,0.2000);display:flex;flex-direction: row;justify-content:flex-start;align-items:center;">
					<div style="width:90px;text-align:right;"> 成交总价</div>
					<div style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.tradeprice}</div>
				</div>
			</div >`
			}
			if (itemdata.location) {
				sContent += `
				<div style="font-weight: 500;
color: #000000;line-height: 24px;text-shadow: 0px 5px 26px rgba(0,0,0,0.2000);display:flex;flex-direction: row;justify-content:flex-start;align-items:center;">
					<div style="width:90px;text-align:right;"> 地块位置</div>
					<div style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.location}</div>
				</div>	
			</div >`
			}

			if (itemdata.loupanzhuizong) {
				sContent += `
				<div style="font-weight: 500;
color: #000000;line-height: 24px;text-shadow: 0px 5px 26px rgba(0,0,0,0.2000);display:flex;flex-direction: row;justify-content:flex-start;align-items:center;">
					<div style="width:90px;text-align:right;">楼盘追踪</div>
					<div style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.loupanzhuizong}</div>
				</div>
			</div >`
			}


			if (itemdata.tradedate) {
				sContent += `
				<div style="font-weight: 500;
color: #000000;line-height: 24px;text-shadow: 0px 5px 26px rgba(0,0,0,0.2000);display:flex;flex-direction: row;justify-content:flex-start;align-items:center;">
					<div style="width:90px;text-align:right;"> 成交日期</div>
					<div style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.tradedate}</div>
				</div>
			</div >`
			}

			if (itemdata.floorprice) {
				sContent += `
				<div style="font-weight: 500;
color: #000000;line-height: 24px;text-shadow: 0px 5px 26px rgba(0,0,0,0.2000);display:flex;flex-direction: row;justify-content:flex-start;align-items:center;">
					<div style="width:90px;text-align:right;"> 楼面价</div>
					<div style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.floorprice}</div>
				</div>
			</div >`
			}
			if (itemdata.overrage) {
				sContent += `
				<div style="font-weight: 500;
color: #000000;line-height: 24px;text-shadow: 0px 5px 26px rgba(0,0,0,0.2000);display:flex;flex-direction: row;justify-content:flex-start;align-items:center;">
					<div style="width:90px;text-align:right;"> 溢价率</div>
					<div style="color:#333;font-weight:400;flex:1;overflow:hidden;margin-left:20px;">${itemdata.overrage}</div>
				</div>
			</div >`
			}

			sContent += `
			<div style="width:100%;display:flex;justify-content:center;align-items:center;height:40px;">
					<a type="primary" class="more" id="more"  style="margin:0 auto;display:inline-block;height:24px;line-height:24px;width:160px;color:#fff;background:#409EFF;border-radius:5px;border:solid 1px #409EFF;text-align:center;cursor:pointer;" routeid="${itemdata.id}"> 查看更多</a>
			</div>
			</div > `;

			return sContent;

		},


		infoWindowClose(e) {
			this.infoWindow.show = false
		},

		infoWindowOpen(e) {
			this.infoWindow.show = true
		},


		handerPolygondata(polygondata) {
			let polygonpath = []
			this.handleListData(polygondata)

			const pointobj = polygondata.map(item => {
			
				const allset = item.set //所有多边形
				if(item.set){
					const setObj = JSON.parse(allset)
					const rowpolygonpath = setObj.map(itemSingle => {
						// data 是一个多变形的数据
						// 处理一个多变形的方法
						const singlepath = this.handleSingleData(item.id,itemSingle)
						polygonpath.push(singlepath)
					})

				}
				
			})
			console.log('-polygonPath---',polygonpath)
			this.polygonPath = polygonpath
		},

		handleSingleData(id,data) {

			let obj = {
				id:id,
				path: [],
				filecolor: '#000',
				strokcolor: '#409EFF',
				weight: this.strokeWeight,
				strokeopacity: this.strokeOpacity,
				fillopacity: this.fillOpacity,

			}

			const pointobj = data.lnglat.split(';')
			const pointArr = pointobj.map(point => {
				const lnglat = point.split(',')
				return { lng: parseFloat(lnglat[0]), lat: parseFloat(lnglat[1]) }
			})
			obj.path = pointArr
			obj.filecolor = data.color

			return obj

		},

		getPolygondata() {
			//获取polygondata
			this.changeBounds()
		},



		handler({ BMap, map }) {
			// var view = map.getViewport(eval(this.markerdata))
			// this.zoom = view.zoom
			// this.centerpoint = view.center
		},

		//缩放结束事件
		syncCenterAndZoom(e) {
			//获取当前中心点
			const { lng, lat } = e.target.getCenter()

			//获取当前的zoom
			const currentZoom = e.target.getZoom()

			if (currentZoom >= 13 ) {
				
				//获取多边形的数据
				this.map = e.target
				this.getPolygondata()
				this.showPloygon = true
			} else {
				this.showPloygon = false
			}


			this.currentzoom = currentZoom
			// this.$emit('curzoom',currentZoom)
		},


		//处理百度地图数据
		handlerBaiduData(data) {

			const typearr = [
				this.type1,
				this.type1,
				this.type2,
				this.type3,
				this.type4
			]
			const colorarr = [
				this.bg1,
				this.bg1,
				this.bg2,
				this.bg3,
				this.bg4
			]

			const handlerdata = data.map(item => {
				
				item.icon = typearr[item.usecate]
				item.content = item.locationnum || item.noticenum || item.locationname || item.location
				item.style = { color: '#000', borderColor: colorarr[item.usecate], background: colorarr[item.usecate], fontSize: '12px', borderRadius: '5px', paddingRight: '3px' }
				item.offset = { width: 25, height: 12 }
				item.checked = true
				return item
			})
			// console.log('handlerdata', handlerdata)
		

			if (data.length > 100 ||data.length === 0 ) {
				this.less100 = false
				// this.clustererdata = handlerdata

				this.markerdata =  handlerdata
				

			} else {
				
				this.less100 = true


				this.markerdata = handlerdata



			}


			this.handleListData(data)
		},


		//点击左侧列表跳转到右侧地图的点位置
		toMapPoint(data) {
			// alert(this.less100)
			if (this.less100) {
				var that = this

				this.rightList = this.rightList.map(item => {

					if (item.id === data.id) {
						const id = item.id
						// alert(id)
						// const newMarkerdata = []

						this.markerdata = this.markerdata.map(marker => {
							if (marker.id === id) {

								marker.checked = item.checked = !data.checked

							}
							return marker
						})


						if(that.polygonPath.length > 0 ){

							that.polygonPath.map(polygon=>{
								if(polygon.id === id){
									if(!data.checked){
										polygon.fillopacity =  0
									}else{
										polygon.fillopacity =  1
									}
								
								}

								return polygon
							})

						}



					}
					return item
				})

			} else {
				this.currentSelectItemID = data.id
				this.markerclick(data)
			}


	

		},
		//处理右侧列表数据
		handleListData(data) {
			// console.log('data',data)
			const list = []
			data.map((item, index) => {
				if (index < 100) {
					item.title = item.locationnum || item.locationname || item.noticenum ||  item.location
					item.checked = true
					list.push(item)
				}
			})
			// console.log('list',list)
			this.rightList = list
		},

		changeBounds() {
			const bounds = this.map.getBounds()
			const sw = bounds.getSouthWest()
			const ne = bounds.getNorthEast()
			this.where.ll_lng = sw.lng
			this.where.ll_lat = sw.lat
			this.where.ru_lng = ne.lng
			this.where.ru_lat = ne.lat
		},


	}
};
</script>

<style lang="scss" scoped>

.map {
	width: 100%;
	min-height: 500px;
	height: calc(100vh - 100px);
}

.rightmap {
	width: 15%;
	margin-left: 1%;
	height: 100%;
	overflow: hidden;
	background: #F0F3F8;
	padding-left: 5px;
	display: flex;
	flex-direction: column;

	// background:red;
	.listtitle {
		position: relative;
		top: 0;
		height: 50px;
		line-height: 50px;
		font-size: 16px;
		font-weight: bold;
	}

	.listbox {
		// max-height: calc( 100vh - 220px );
		// height: calc( 100vh - 260px );
		// display: flex;
		flex: 1;
		overflow-y: scroll;
	}

	.list {
		height: auto;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;


		.listitem {
			padding-left: 10px;
			height: 24px;
			line-height: 24px;
			overflow: hidden;
			cursor: pointer;
			display: flex;
			align-items: flex-start;
			font-weight: 500;
			// background: red;
		}

		.current {
			color: #28A7E1;
			font-weight: bold;
		}

	}

}

.pageContainer {
	width: 100%;
	height: 100%;
	display: flex;
}

.leftmap {
	width: 84%;
	// background: red;

}
</style>