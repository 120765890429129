<template>
  <div class="breadcrumb">
    <img :src="iconname" class="iconname" />
    <div class="breadname">{{ name }} <span v-if="landname">-- {{ landname }}</span></div>

  </div>
</template>
<script>
import { datadetail } from '@/api/data.js'
export default {
  data() {
    return {
      name: '',
      iconname: '',
      landname: ''
    }

  },
  computed: {
    currentdata: {
      get() {
        return this.$store.state.currentLandData
      },
      set(v) {
        this.$store.commit('currentLandData', v)
      }
    }
  },

  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to) {

        this.iconname = to.meta.icon
        //操作
        if (to.params.id) {
          // alert(this.currentdata.id)
          // alert(to.params.id)
          if (Number(to.params.id) !== Number(this.currentdata.id)) {
            datadetail({ id: to.params.id }).then(res => {
              const currentdata = res.data
              this.landname = currentdata.locationnum || currentdata.locationame || currentdata.location || currentdata.noticenum
              this.currentdata = currentdata
            })
          } else {
            this.landname = this.currentdata.locationnum || this.currentdata.locationame || this.currentdata.location || this.currentdata.noticenum
          }



        }
        //深度监听，同时也可监听到param参数变化
      },
      deep: true,
    }
  },





  mounted() {


    const parentname = this.$router.history.current.meta.parentname || null
    if (parentname != null) {
      this.name = parentname
    } else {
      this.name = this.$router.history.current.meta.name
    }

    // alert( this.$router.history.current.meta.icon )

    this.iconname = this.$router.history.current.meta.icon


  }

}


</script>
<style lang="scss">
.breadcrumb {
  width: 100%;
  height: 50px;
  background: #FFF;
  display: flex;
  justify-items: center;
  align-items: center;
  border: solid 4px #F0F3F8;
  box-sizing: border-box;

  .iconname {
    margin-left: 20px;
    font-size: 16px;
    height: 16px;
    width: 16px;

  }

  .breadname {
    font-size: 16px;
    margin-left: 10px;
    font-weight: 600;
  }
}
</style>